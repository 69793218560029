import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useCallback, useEffect, useState} from "react";
import {useApplicationContext} from "../../../ApplicationContext";
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader, styled,
    useMediaQuery,
    useTheme
} from '@mui/material';
import Typography from "@mui/material/Typography";
import {GetSupportUserData} from "../../../apiClient/Api";
import {AxiosResponse} from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faServer} from '@fortawesome/pro-solid-svg-icons'
import dayjs from "dayjs";


export interface IDeleteUserDialogProps {
    id?: string;
    advancedInfoClosed: () => void;
}

export function AdvancedInfoDialog(props: IDeleteUserDialogProps) {

    const context = useApplicationContext();
    const theme = useTheme();
    const [supportUserData, setSupportUserData] = useState<undefined | GetSupportUserData>(undefined);
    const [initialized, setInitialized] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const axiosCallback = useCallback((user : AxiosResponse<GetSupportUserData, any>) => {
        if (user.data != undefined) {
            setSupportUserData(user.data)
        }
    }, [supportUserData])
    const reset = () => {
        setInitialized(false)
        setSupportUserData(undefined)
    }
    const open = props.id != undefined && initialized;
    useEffect(() => {
        if (!initialized && props.id != undefined) {
            setInitialized(true);
            context.api.user.userGetSupportUserData(props.id).then(axiosCallback).catch(context.apiError)
        }
    }, [props.id, initialized])

    const handleClose = () => {
        props.advancedInfoClosed();
        reset();

    };


    const NarrowlistItem = styled(ListItem)({
        paddingTop: 0,
        paddingBottom: 0,
    });

    return (

        <Dialog
            open={open}
            fullScreen={fullScreen}
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Nutzerdaten einsehen"}
            </DialogTitle>
            <DialogContent>
                <List dense disablePadding subheader={`Benutzer`}>
                    <NarrowlistItem>
                        <ListItemText primary={supportUserData?.name} secondary={"Name"}  />
                    </NarrowlistItem>
                    <NarrowlistItem>
                        <ListItemText primary={supportUserData?.eMail} secondary={"Mail"}  />
                    </NarrowlistItem>
                    <NarrowlistItem>
                        <ListItemText primary={supportUserData?.locked ? "Ja" : "Nein"} secondary={"Gesperrt"}  />
                    </NarrowlistItem>
                    <NarrowlistItem>
                        <ListItemText primary={supportUserData?.acceptedTerms == undefined ? "-" : supportUserData?.acceptedTerms.versionTitle} secondary={"Akzeptierte Bedingungen"}  />
                    </NarrowlistItem>
                    <NarrowlistItem>
                        <ListItemText primary={supportUserData?.acceptedTerms == undefined ? "-" : dayjs(supportUserData?.acceptedTerms.created).format("DD.MM.YYYY")} secondary={"Akzeptierte Bedingungen (Datum)"}  />
                    </NarrowlistItem>
                    <NarrowlistItem>
                        <ListItemText primary={supportUserData?.lastLogin == undefined ? "-" : dayjs(supportUserData?.lastLogin?.toString()).format("DD.MM.YYYY HH:mm:ss")} secondary={"Zuletzt eingeloggt"}  />
                    </NarrowlistItem>
                </List>
                <Divider />
                {supportUserData?.supportContracts?.map(contract => <>
                    <List dense disablePadding subheader={`Wartungsvertrag ${contract.title}`}>
                        <NarrowlistItem>
                            <ListItemText primary={contract.customerName} secondary={"Kunde"}  />
                        </NarrowlistItem>
                        <NarrowlistItem>
                            <ListItemText primary={contract.customerId} secondary={"Kundennummer"}  />
                        </NarrowlistItem>
                        <NarrowlistItem>
                            <ListItemText primary={contract.contractId} secondary={"Vertagsnummer"}  />
                        </NarrowlistItem>
                        <NarrowlistItem>
                            <ListItemText primary={contract.comment} secondary={"Beschreibung"}  />
                        </NarrowlistItem>
                        <NarrowlistItem>
                            <ListItemText primary={contract.infinite ? 'Ja' : 'Nein'} secondary={"Unbegrenzte Laufzeit"}  />
                        </NarrowlistItem>

                        <ListSubheader >
                            <ListItemText primary={"FilterTags"} sx={{color: theme.palette.secondary.main}}  />
                        </ListSubheader>
                        <List>
                            {contract.filterTags?.map(tag =><NarrowlistItem><ListItemIcon><FontAwesomeIcon icon={faCheckSquare}/></ListItemIcon> <ListItemText>{tag.name}</ListItemText></NarrowlistItem>)}
                        </List>
                        <ListSubheader >
                            <ListItemText primary={"Systeme"} sx={{color: theme.palette.secondary.main}}  />
                        </ListSubheader>
                        <List>
                            {contract.supportCodes?.map(tag =><NarrowlistItem><ListItemIcon><FontAwesomeIcon icon={faServer}/></ListItemIcon> <ListItemText primary={tag.name} secondary={tag.id} /></NarrowlistItem>)}
                        </List>
                    </List>
                    <Divider color={"primary"}/>
                 </>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Schließen</Button>

            </DialogActions>
        </Dialog>

    );
}

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel, MenuItem,
    NativeSelect, Select,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useApplicationContext} from "../../../ApplicationContext";
import {useSnackbar} from "notistack";
import {EUserGroup, EVersionStage} from "../../../apiClient/Api";
import GetEnumKeys from "../../../GetEnumKeys";

export interface IAddUserComponentProps {
    open: boolean
    closeComponent: (created: boolean) => void
    userId: string | undefined
}

interface CreateUserData {
    mail: string;
    name: string;
    anrede: string;
    password: string;
    passwordRepeat: string;
    userGroup: number;

}




const defaultCreateUser: CreateUserData = {
    name: "", anrede: "", mail: "", password: "", passwordRepeat: "", userGroup: 4
}
export const AddUserComponent = (props: IAddUserComponentProps) => {

    const [loggedIn, setLoggedIn] =useState<boolean>(true)
    const [newUser, setNewUser] = useState<CreateUserData>(defaultCreateUser)
    const {enqueueSnackbar} = useSnackbar();
    const reset = () => {
        setMail("")
        setFirstName("")
        setanrede("")
        setPassword("")
        setPasswordRepeat("")
        setUserGroup(EUserGroup.CUSTOMER)
        setUserStage(EVersionStage.RELEASE)
        setInitialized(false);
    }

    useEffect(() => {
        if (props.userId != undefined && !initialized) {
            context.api.user.userGetUser(props.userId).then(result => {
                const data = result.data
                setMail(data.eMail ?? "")
                setFirstName(data.name ?? "");
                setanrede(data.anrede ?? "");
                setLoggedIn(data.lastLogin != undefined)
                setUserGroup(data.userGroup ?? EUserGroup.CUSTOMER)
                setUserStage(data.minAllowedStage ?? EVersionStage.RELEASE)

            }).catch(context.apiError)
            setInitialized(true);
        }
    })
    const [initialized, setInitialized] = useState(false);
    const [mail, setMail] = useState<string>("");
    const [password, setPassword] = useState<string>("")
    const [passwordRepeat, setPasswordRepeat] = useState("")
    const [name, setFirstName] = useState<string>("");
    const [anrede, setanrede] = useState<string>("")
    const [userGroup, setUserGroup] = useState<EUserGroup>(EUserGroup.CUSTOMER);

    const [userStage, setUserStage] = useState<EVersionStage>(EVersionStage.RELEASE);

    const context = useApplicationContext();


    function handleClose() {
        props.closeComponent(false);
        reset()

    }

    function handleResendData(){
        if(!props.userId)
            return;

        context.api.user.userResendFirstLogin(props.userId).then(u => {
            if(u.data)
                handleClose();
        }).catch(context.apiError)
    }

    function handleSave() {


        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!mail.toLowerCase().match(re)) {
            enqueueSnackbar("Sie müssen eine gültige Mail Adresse eingeben", {
                variant: "warning",
                preventDuplicate: true
            })
            return;
        }

        if ((password.length < 8 && props.userId == undefined) || (password.length > 0 && password.length < 8 && props.userId == undefined)) {
            enqueueSnackbar("Passwörter müssen mindestens 8 zeichen lang sein", {
                variant: "warning",
                preventDuplicate: true
            })
        }
        if (password !== passwordRepeat) {
            enqueueSnackbar("Passwörter sind nicht identisch", {variant: "warning", preventDuplicate: true})
            return;
        }

        if (props.userId != undefined) {
            context.api.user.userEditUser(props.userId, {
                name: name,
                anrede: anrede,
                userGroup: userGroup,
                password: password,
                passwordRepeat: passwordRepeat,
                minAllowedStage: userStage
            }).then(() => {
                props.closeComponent(true);
                reset()
            }).catch(context.apiError)
        } else {
            context.api.user.userAddUser({
                eMail: mail,
                name: name,
                anrede: anrede,
                userGroup: userGroup,
                password: password,
                passwordRepeat: passwordRepeat,
                minAllowedStage: userStage
            }).then(() => {
                props.closeComponent(true);
                reset()
            }).catch(context.apiError)
        }


    }

    return <Dialog open={props.open} onClose={handleClose}>
        {props.userId == undefined ? <DialogTitle>Nutzer erstellen</DialogTitle> :
            <DialogTitle>Nutzer editieren</DialogTitle>}
        <DialogContent>
            <DialogContentText>Neuen Nutzer für die Verwendung im Support System erstellen. <br/>
                Sie können anschließend Support verträgen zugeordnet werden.</DialogContentText>

            <TextField
                margin="dense"
                id="mail"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                value={mail}
                disabled={props.userId != undefined}
                onChange={event => setMail(event.target.value)}

            />
            <TextField
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={name}
                onChange={event => setFirstName(event.target.value)}

            />
            <TextField
                margin="dense"
                id="anrede"
                label="Anrede"
                type="text"
                fullWidth
                variant="standard"
                value={anrede}
                onChange={event => setanrede(event.target.value)}

            />
            <TextField
                margin="dense"
                id="password"
                label="Passwort"
                type="password"
                fullWidth
                variant="standard"
                value={password}
                onChange={event => setPassword(event.target.value)}

            />
            <TextField
                margin="dense"
                id="passwordRepeat"
                label="Passwort wiederholen"
                type="password"
                fullWidth
                variant="standard"
                value={passwordRepeat}
                onChange={event => setPasswordRepeat(event.target.value)}

            />
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="userstage-native">
                    Versionslevel
                </InputLabel>

                <Select
                    fullWidth
                    inputProps={{
                        name: 'userStage',
                        id: 'userstage-native',
                    }}
                    variant={"standard"}

                    value={userStage}
                    onChange={event => {
                        if(typeof  event.target.value == "number"){
                            setUserStage(event.target.value);
                        }
                    }}
                >
                    {GetEnumKeys(EVersionStage).map((a, b) => {
                        return <MenuItem key={a} value={b}>{a}</MenuItem>
                    })}
                </Select>
            </FormControl>
                <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="usergroup-native">
                    Nutzergruppe
                </InputLabel>
                <Select
                    value={userGroup}
                    onChange={event => {
                        if(typeof  event.target.value == "number") {
                            setUserGroup(event.target.value);
                        }
                    }}
                    variant={"standard"}
                    inputProps={{
                        name: 'userGroup',
                        id: 'usergroup-native',
                    }}
                >
                    {context.userData!.userGroup! <= EUserGroup.SUPER_ADMIN && <MenuItem value={EUserGroup.SUPER_ADMIN}>Super Admin</MenuItem>}
                    {context.userData!.userGroup! <= EUserGroup.ADMIN && <MenuItem value={EUserGroup.ADMIN}>Admin</MenuItem>}
                    {context.userData!.userGroup! <= EUserGroup.ADMIN && <MenuItem value={EUserGroup.DEVELOPMENT}>Entwickler</MenuItem>}
                    {context.userData!.userGroup! <= EUserGroup.ADMIN && <MenuItem value={EUserGroup.OFFICE}>Büro</MenuItem>}
                    <MenuItem value={EUserGroup.CUSTOMER}>Kunde</MenuItem>
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            {!loggedIn && <Button color={"secondary"} onClick={handleResendData}>Erneut versenden</Button>}
            <Button onClick={handleClose}>Abbrechen</Button>
            <Button onClick={handleSave}>Speichern</Button>
        </DialogActions>
    </Dialog>
}

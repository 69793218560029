export function determineMimeType(inputFile: File): string {
    // Define a mapping for custom file extensions to MIME types
    const customMimeTypes: { [key: string]: string } = {
        '.rel.upg': 'application/vnd.reichert-systems.rel',
        '.dbg.upg': 'application/vnd.reichert-systems.dbg',
        '.rel.o1.upg': 'application/vnd.reichert-systems.rel',
        '.ioedump': 'application/vnd.reichert-systems.ioedump',
        // Add more custom types as needed
    };

    // Default MIME type for other files
    const defaultMimeType = 'application/octet-stream';

    // Function to check if the file name ends with any of the custom extensions
    const getCustomMimeType = (filename: string): string | null => {
        for (const extension in customMimeTypes) {
            if (filename.endsWith(extension)) {
                return customMimeTypes[extension];
            }
        }
        return null;
    };

    // Get the custom MIME type, if applicable
    const customMimeType = getCustomMimeType(inputFile.name);

    // Use the existing MIME type if the custom one is not found, and default if both are missing
    return customMimeType || inputFile.type || defaultMimeType;
}

import {
    AppBar, Avatar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container, CssBaseline, FormControlLabel,
    IconButton,
    TextField,
    Toolbar,
    useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useApplicationContext} from "../ApplicationContext";
import {ChangePasswordData, GetTermsData} from "../apiClient/Api";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {Copyright} from "Components/Copyright";

export const ChangePassword = () => {
    const context = useApplicationContext();
    const [latestTerms, setLatestTerms] = useState<GetTermsData | undefined>()
    const [initialized, setInitialized] = useState(false)
    const theme = useTheme();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var currentPassword = data.get('password')?.toString();
        var newPassword = data.get('password-new')?.toString();
        var newPasswordRepeat =data.get('password-new-repeat')?.toString()

        if(currentPassword == undefined || newPassword == undefined || newPasswordRepeat == undefined)
            return;

        const formData : ChangePasswordData = {
            currentPassword,
            newPassword,
            newPasswordRepeat
        };
        context.updatePassword(formData);
    };

    return <>
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                    >

                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Reichert Support System
                    </Typography>
                    <Button color="inherit" onClick={context.signOut}>Ausloggen</Button>
                </Toolbar>
            </AppBar>
        </Box>

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Passwort ändern
                </Typography>
                <Typography>Sie müssen ihr aktuelles Passwort ändern</Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Aktuelles Passwort"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password-new"
                        label="Neues Passwort"
                        type="password"
                        id="password-new"
                        autoComplete="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password-new-repeat"
                        label="Neues Passwort wiederholen"
                        type="password"
                        id="password-new-repeat"
                        autoComplete="new-password-repeat"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Passwort ändern
                    </Button>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    </>
}

import React, {useState, useEffect} from 'react';
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    MenuItem,
    Menu,
    useTheme,
    useMediaQuery,
    AppBarProps as MuiAppBarProps,
    styled
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBars, faGlobeEurope,
    faRightToBracket
} from '@fortawesome/pro-solid-svg-icons';
import {useApplicationContext} from "ApplicationContext";
import {useTranslation} from 'react-i18next';
import {EUserGroup} from 'apiClient/Api';
import LanguageButton from "./LanguageButton";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface SupportAppBarProps extends AppBarProps{
    handleDrawerToggle: () => void;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const SupportAppBar: React.FC<SupportAppBarProps> = ({open, handleDrawerToggle}) => {
    const context = useApplicationContext();
    const {i18n, t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleMenuClose = () => setAnchorEl(null);
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
        handleMenuClose();
    };

    const languages = [
        {code: 'de', name: t('base.languages.german')},
        {code: 'en', name: t('base.languages.english')},
        {code: 'es', name: t('base.languages.spanish')},
        {code: 'fr', name: t('base.languages.french')},
    ];

    const isDeveloperOrAbove = context.userData?.userGroup! <= EUserGroup.DEVELOPMENT;

    return (
        <AppBar position="absolute" open={open}>
            <Toolbar
                sx={{
                    pr: '24px',
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    sx={{
                        marginRight: '36px',
                        ...(open && {display: 'none'}),
                    }}
                >
                    <FontAwesomeIcon icon={faBars}/>
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    {context.pageTitle}
                </Typography>

                <Typography variant={"caption"}>{context.userData?.name}</Typography>
                <IconButton color="inherit" onClick={context.signOut}>
                    <FontAwesomeIcon icon={faRightToBracket}/>
                </IconButton>
                {isDeveloperOrAbove && (
                    <LanguageButton />
                )}
            </Toolbar>
        </AppBar>
    );
}

export default SupportAppBar;

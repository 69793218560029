import {
    DataGrid,
    GridActionsCellItem,
    GridColDef, GridComparatorFn,
    GridEventListener,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    GridToolbarContainer,
    MuiEvent,
    useGridApiContext
} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Select, SelectChangeEvent, TableContainer, TextField
} from "@mui/material";
import {useDocumentTitle} from "../Hooks/useDocumentTitle";
import {useApplicationContext} from "../ApplicationContext";
import AddIcon from "@mui/icons-material/Add";
import {AddFileTypeData, GetFilterTagData, GetHostSystemData} from "../apiClient/Api";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Paper from "@mui/material/Paper";
import ListAltIcon from "@mui/icons-material/ListAlt";


const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp<GetHostSystemData>) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps) {
    const {setRows, setRowModesModel} = props;

    const handleClick = () => {
        const id = "NEW";
        setRows((oldRows) => [...oldRows, {
            id,
            name: 'FileType',
            hostSystemName: "",
            hostSystemId: "",
            description: "",
            isNew: true
        }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
                Datei Typ hinzufügen
            </Button>
        </GridToolbarContainer>
    );
}

export const AdminFileTypes = () => {
    useDocumentTitle("base.pages.fileTypes")

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [selectedRow, setSelecedRow] = useState<GridRowId | undefined>(undefined);
    const [selectedRowRegex, setSelectedRowRegex] = useState<GridRowId | undefined>(undefined);

    const [rows, setRows] = useState<GridRowsProp>([])
    const [hostSystems, setHostSystems] = useState<GetHostSystemData[]>([])
    const [filterTags, setFilterTags] = useState<GetFilterTagData[]>([])
    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteEntryValue, setDeleteEntryValue] = useState("")
    const [fileFilterValue, setFileFilter] = useState<Array<string>>([])
    const context = useApplicationContext();
    const controller = context.api.fileType;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {
        return <SelectEditInputCell {...params} />;
    };

    const renderFilterCell: GridColDef['renderCell'] = (params) => {
        return <MultiSelectEditInputCell {...params} />;
    };

    function renderFilter(params: GridRenderCellParams<any, GetFilterTagData[]>) {

        var text = "";
        params.value?.forEach(value => {
            if (text.length > 0)
                text += "; "
            text += value.name;
        })

        return <>{text}</>;
    }


    function SelectEditInputCell(props: GridRenderCellParams) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event: SelectChangeEvent) => {
            await apiRef.current.setEditCellValue({id, field, value: event.target.value});
            apiRef.current.stopCellEditMode({id, field});
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{height: 1}}
                native
                autoFocus
            >
                <option value="">Nicht ausgewählt</option>
                {hostSystems.map(hostSystem => <option key={hostSystem.id}  value={hostSystem.id!}>{hostSystem.name}</option>)}
            </Select>
        );
    }


    function MultiSelectEditInputCell(props: GridRenderCellParams) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();
        const handleChange = async (event: any, value: GetFilterTagData[]) => {
             await apiRef.current.setEditCellValue({id, field, value: value});
             await apiRef.current.stopCellEditMode({id, field});
        };
        return (<Autocomplete multiple value={value} fullWidth filterSelectedOptions getOptionLabel={(option) => option.name!}
                              onChange={handleChange}
                              key={"t"}
                              isOptionEqualToValue={(first: GetFilterTagData, second: GetFilterTagData) => first.identifier == second.identifier}
                              options={filterTags} renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Dateifilter"
                    placeholder="CLFS, BosCompakt, ..."
                />
            )}/>
        );
    }


    function renderHostSystem(params: GridRenderCellParams<any, string>) {
        var system = hostSystems.find(data => data.id === params.value?.toString())
        if (system) {
            return <>{system.name}</>
        }
        return <>{params.value}</>;
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        var row = params.row;
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {

        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setSelecedRow(id);
    };

    const handleListEdit = (id: GridRowId) => () => {
        var row = rows.find(f => f.id === id);
        if(row  !== undefined){
            setFileFilter(row.matchingFiles)
        }
        setSelectedRowRegex(id);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };


    const processRowUpdate = async (newRow: GridRowModel) => {

        var filterTags = newRow.filterTags.flatMap((f : GetFilterTagData) => f.identifier);
        if (newRow.isNew) {
            try {

                var rowData: AddFileTypeData = {
                    name: newRow.name,
                    description: newRow.description,
                    hostSystemId: newRow.hostSystemId,
                    filterTags: filterTags
                }

                var row = await controller.fileTypeAddFileType(rowData);
                if (row.data != undefined) {
                    setRows([...rows.filter((row) => row.id !== newRow.id), row.data]);
                }
                return row.data;

            } catch (e) {
                context.apiError(e)
                return null;
            }
        } else {
            try {

                var rowData: AddFileTypeData = {
                    name: newRow.name,
                    description: newRow.description,
                    hostSystemId: newRow.hostSystemId,
                    filterTags: filterTags
                }

                var row = await controller.fileTypeEditFileType(newRow.id, rowData);
                if (row.data != undefined) {
                    setRows([...rows.filter((row) => row.id !== newRow.id), row.data]);
                }
                return row.data;
            } catch (e) {
                context.apiError(e)
                return null;
            }
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleFileFilterDialogClose = (save: boolean) => () => {

        if (save && selectedRowRegex !== undefined) {

            var value = rows.find(r => r.id === selectedRow)?.matchingFiles


                if (value !== undefined &&  fileFilterValue !== value)
                    return;

                var row = selectedRowRegex.toString();
                controller.fileTypeUpdateFileTypes(row, fileFilterValue).then(e => {
                     fetchData();
                    setFileFilter([])
                    setSelectedRowRegex(undefined);
                }).catch(context.apiError)


        } else {
            setSelectedRowRegex(undefined)
            setFileFilter([])
        }
    }

    const handleDeleteDialogClose = (save: boolean) => () => {
        if (save && selectedRow != undefined) {

            var value = rows.find(r => r.id == selectedRow)?.name
            if (value == undefined) {
                setSelecedRow(undefined)
            } else {

                if (deleteEntryValue != value)
                    return;

                var row = selectedRow.toString();
                controller.fileTypeDeleteFileType(row).then(e => {
                    setRows(srows => srows.filter((row) => row.id !== row));
                    setSelecedRow(undefined);
                    fetchData();
                    setDeleteEntryValue("")
                }).catch(context.apiError)
            }

        } else {
            setSelecedRow(undefined)
            setDeleteEntryValue("")
        }

    }

    async function fetchData() {
        setIsFetching(true)
        try {
            var hostSystems = await controller.fileTypeGetHostSystems();
            setHostSystems(hostSystems.data);
            var filterTags = await context.api.filterTags.filterTagsListFilterTags();
            setFilterTags(filterTags.data);
            var value = await controller.fileTypeGetFileTypes()
            setRows(value.data)
        } catch (e) {
            context.apiError(e)
        }
        setIsFetching(false)
    }

    const sortHostSystemComparator = (v1: string, v2: string) => {
        var system1 = hostSystems.find(data => data.id === v1)
        var system2 = hostSystems.find(data => data.id === v2)
        if(system1?.name)
            v1 = system1.name
        if(system2?.name)
            v2 = system2.name
        return v1.localeCompare(v2)
    }

    useEffect(() => {
        if (!isData) {
            setIsData(true);
            fetchData();
        }

    }, [rows, isData, isFetching])

    return <>

        <Dialog
            open={selectedRow != undefined}
            onClose={handleDeleteDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Löschen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten Sie den Eintrag wirklich löschen?
                </DialogContentText>
                {selectedRow != undefined && <>
                    <DialogContentText fontWeight={"bold"} id="alert-dialog-description">
                        Zum löschen {rows.find(r => r.id == selectedRow)?.name} in das Textfeld eingeben
                    </DialogContentText></>}
                <TextField value={deleteEntryValue} onChange={target => setDeleteEntryValue(target.target.value)}
                           fullWidth margin={"dense"}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteDialogClose(false)} color={"error"}>Nein</Button>
                <Button onClick={handleDeleteDialogClose(true)} color={"success"} autoFocus>
                    Ja
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={selectedRowRegex != undefined}
            onClose={handleFileFilterDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Dateifilter (Build Server)"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Editieren der Dateifilter. Jede Zeile beinhaltet einen Dateifilter.
                </DialogContentText>

                <TextField value={fileFilterValue.join("\n")} onChange={target => setFileFilter(target.target.value.split("\n").map(item => item.trim()))}
                           fullWidth margin={"dense"} multiline minRows={5}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFileFilterDialogClose(false)} color={"error"}>Abbrechen</Button>
                <Button onClick={handleFileFilterDialogClose(true)} color={"success"} autoFocus>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
        <TableContainer component={Paper} style={{margin: 4}}>
            <DataGrid
                editMode={"row"}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: {setRows, setRowModesModel},
                }}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) => console.error(error)}

                getRowId={(row) => row.id!}
                columns={[
                    {
                        field: "name", headerName: "Name", editable: true,
                        flex: 1
                    },
                    {
                        field: "hostSystemId",
                        headerName: "Betriebssystem",
                        editable: true,
                        renderEditCell: renderSelectEditInputCell,
                        renderCell: renderHostSystem,
                        sortComparator: sortHostSystemComparator,
                        maxWidth: 200,
                        flex: 1
                    },
                    {
                        field: "filterTags",
                        headerName: "Filter",
                        editable: true,
                        renderEditCell: renderFilterCell,
                        renderCell: renderFilter,
                        maxWidth: 400,
                        flex: 1
                    },
                    {
                        field: "description",
                        headerName: "Beschreibung",
                        editable: true,
                        flex: 1
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Actions',
                        width: 100,
                        cellClassName: 'actions',
                        getActions: ({id}) => {
                            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                            if (isInEditMode) {
                                return [
                                    <GridActionsCellItem
                                        icon={<SaveIcon/>}
                                        label="Save"
                                        onClick={handleSaveClick(id)}
                                    />,
                                    <GridActionsCellItem
                                        icon={<CancelIcon/>}
                                        label="Cancel"
                                        className="textPrimary"
                                        onClick={handleCancelClick(id)}
                                        color="inherit"
                                    />,
                                ];
                            }

                            return [
                                <GridActionsCellItem
                                    icon={<EditIcon/>}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={handleEditClick(id)}
                                    color="inherit"
                                />,
                                <GridActionsCellItem
                                    icon={<ListAltIcon/>}
                                    label="Liste"
                                    onClick={handleListEdit(id)}
                                    color="inherit"
                                />,
                                <GridActionsCellItem
                                    icon={<DeleteIcon/>}
                                    label="Delete"
                                    onClick={handleDeleteClick(id)}
                                    color="inherit"
                                />,

                            ];
                        },
                    }
                ]}
                rows={rows} loading={isFetching} autoHeight={true}/>
        </TableContainer>
    </>
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider, useMediaQuery} from "@mui/material";
import {green, purple} from "@mui/material/colors";
import {ApplicationContextProvider} from "./ApplicationContext";
import {SnackbarProvider} from "notistack";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import './i18nextConf'


const Root = () => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        main: "#0A858C",
                    },
                    secondary: {
                        main: "#F7A70A",
                    },
                },
            }),
        [prefersDarkMode],
    );

        return <ThemeProvider theme={theme}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SnackbarProvider maxSnack={3}>
                    <ApplicationContextProvider>
                        <App/>
                    </ApplicationContextProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>



}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Root/>
    </React.StrictMode>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConsoleStreamOutputFrame, {ConsoleStreamOutputFrameData} from "./ConsoleStreamOutputFrame";

export interface ConsoleStreamOutputData {
    typeDiscriminator: string;
    output: string;
    parsedOutput: ConsoleStreamOutputFrameData[];
}

interface ErrorBoxProps {
    message: string;
}
const ErrorBox: React.FC<ErrorBoxProps> = ({ message }) => (
    <div style={{ border: '1px solid red', padding: '10px', borderRadius: '5px', color: 'red', marginBottom: '10px' }}>
        <p>Error: {message}</p>
    </div>
);

const ConsoleStreamOutput = ({ row } : {row: ConsoleStreamOutputData}) => {
    if (!row.typeDiscriminator || !row.output || !row.parsedOutput) {
        return <ErrorBox message={`${!row.typeDiscriminator ? "TypeDiscriminator" : !row.output ? "Output" : "ParsedOutput"} is required.`} />;
    }

    const filteredParsedOutput = row.parsedOutput.filter(frame =>
        Object.values(frame).some(value => value !== null && value !== '')
    );


    const paperStyle = {
        marginBottom: '20px',
        padding: '10px',
        borderRadius: '5px',
        border: filteredParsedOutput.length > 1 ? '1px solid red' : '1px solid', // Red for more than one frame, default paper color otherwise
    };

    return (
        <Paper style={paperStyle}>
            {filteredParsedOutput.map((frame, index) => (
                <ConsoleStreamOutputFrame key={index} initialFrame={frame} frameIndex={index} />
            ))}
        </Paper>
    );
};

export default ConsoleStreamOutput;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {useApplicationContext} from "../../ApplicationContext";

export interface IDeleteFileDialog {
    id?: string;
    fileDeleted: (deleted: boolean) => void;
}

export function DeleteFileDialg(props: IDeleteFileDialog) {

    const context = useApplicationContext();
    const [fullName, setFullName] = useState("")
     const [initialized, setInitialized] = useState(false);
    const reset = () => {
        setInitialized(false)

        setFullName("")
    }
    const open = props.id != undefined && initialized;
    useEffect(() => {
        if (!initialized && props.id != undefined) {
            setInitialized(true);
            context.api.version.versionGetFileDetails(props.id).then(file => {
                if(file.data != undefined){
                    setFullName(file.data.name);
                }
            }).catch(context.apiError)
        }
    }, [props.id, initialized])

    const handleClose = () => {
        props.fileDeleted(false);
        reset();

    };




    const handleDelete = () => {
        if (props.id) {

            context.api.version.versionDeleteFile(props.id!).then(() => {
                props.fileDeleted(true);
                reset();
            }).catch(context.apiError)
        }
    }

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Datei löschen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten die Datei {fullName} wirklich löschen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button onClick={handleDelete} autoFocus>
                    Löschen
                </Button>
            </DialogActions>
        </Dialog>

    );
}


import React, {useCallback, useEffect, useState} from "react";

import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridComparatorFn,
    GridRowModes,
    GridRowParams
} from "@mui/x-data-grid";
import {
    Edit,
    Groups,
    Support, QrCode2, OpenInBrowser
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {Button, darken, lighten, styled, TableContainer, ToggleButton} from "@mui/material";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import {useDocumentTitle} from "../../../Hooks/useDocumentTitle";
import {useApplicationContext} from "../../../ApplicationContext";
import {GetCoreDumpDTO} from "../../../apiClient/Api";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUpRightFromSquare,
    faTrash,
} from '@fortawesome/pro-solid-svg-icons'
export const CoreDumpsList = () => {
    useDocumentTitle("Core Dumps")


    const navigate = useNavigate();

    const context = useApplicationContext();
    const [data, setData] = useState<GetCoreDumpDTO[]>([]);
    const [initialized, setInitialized] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [onlyFinished, setOnlyFinshed] = useState<boolean>(false)
    const updateSupportContractData = useCallback(() => {
        setIsFetching(true)
        context.api.gdbService.gdbServiceListResults({onlyFinished}).then(usersdata => {
            setData(usersdata.data);
            setIsFetching(false)
        }).catch(context.apiError)
    }, [onlyFinished])


    useEffect(() => {
        updateSupportContractData()
    }, [onlyFinished, updateSupportContractData])





    const columns: GridColDef[] = [
        {field: "systemName", headerName: "System Name", flex:1},
        {field: "osName", headerName: "Betriebssystem", flex: 1},
        {field: "created", headerName: "Erstellt", flex: 1},
        {
            field: "sucessfullyFinished", headerName: "Erfolgreich", width: 30, valueGetter: (params) => {
                if (params.row.sucessfullyFinished)
                    return "Ja";
              return  "Nein"
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            cellClassName: 'actions',
            getActions: ({id, row}) => {
                return [
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                        label="Öffnen"
                        className="textPrimary"
                        onClick={() => {
                            navigate(`/admin/coredumps/${row.id}`);

                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        label="Löschen"
                        onClick={() => {
                            context.api.gdbService.gdbServiceDeleteCoreDumpById(row.id).then(() => {
                                updateSupportContractData()
                            }).catch(context.apiError)

                        }}
                        color="error"
                    />,
                ];
            },
        }
    ]

    return <>
        <Button onClick={() => setOnlyFinshed(_finished => !_finished)}>{!onlyFinished ? "Nur abgeschlossene" : "Alle"}</Button>
        <Button onClick={() => navigate("/admin/coredumps/upload")}>Neuen CoreDump hochladen</Button>
        <TableContainer component={Paper} style={{margin: 4}}>
            <DataGrid
                getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                rows={data}
                columns={columns}
                loading={isFetching}
                autoHeight={true}></DataGrid>
        </TableContainer>
    </>
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {InputLabel, MenuItem, Radio, RadioGroup, Select} from '@mui/material';
import { FormControl } from '@mui/material';
import { QuickSupportTypes } from './QuickSupportTypes';
import {ErrorEmail, ErrorPhone} from "../QuickSupport";
import {useTranslation} from "react-i18next";
import {Label} from "@mui/icons-material";

export interface ISupportContentForm{
    setName: (name: string) =>  void
    name: string
    setMail: (mail: string) => void
    mail: string
    setNumber: (number: string) => void
    number: string
    setTitle: (title: string) => void
    title: string
    setText:(text: string) => void
    text: string
    setSupportType: (value: number) => void
    supportType : number
    supportIdState: number;
    selectedDSGVO: boolean;
    setSelectedDSGVO: (value: boolean) => void;
    selectedPaySelf: boolean | null;
    setSelectedPaySelf: (value: boolean) => void;
    selectedPayOwner: boolean | null;
    setSelectedPayOwner: (value: boolean) => void;
    selectedIsGuarantee: boolean | null;
    setSelectedIsGuarantee: (value: boolean) => void;

}


export default function SupportContentForm(props: ISupportContentForm) {
    const {t} = useTranslation();


    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("quicksupport.support_request")}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                    <InputLabel id="type-select-label">{t('quicksupport.type')}</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={props.supportType}
                        error={QuickSupportTypes.find(f => f.type == props.supportType) == null}
                        label={t('quicksupport.type')}

                        onChange={event => props.setSupportType(event.target.value as number)}
                    >
                        {QuickSupportTypes.filter(f => props.supportIdState > 0 || !f.needValidId).map(type => <MenuItem value={type.type}>{t(`quicksupport.types.${type.name}`)}</MenuItem>)}
                    </Select>
                    {QuickSupportTypes.find(f => f.type == props.supportType) == null && <span style={{fontSize: 12, color:"#d32f2f"}}>Sie müssen eine Art angeben</span>}
                </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        id="quickSupportName"
                        label={t("quicksupport.support_content.name")}
                        fullWidth
                        autoComplete="cc-quicksupport-name"
                        variant="standard"
                        value={props.name}
                        error={props.name.length < 3}
                        helperText={t("quicksupport.support_content.name_helper")}
                        onChange={e => props.setName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="quickSupportMail"
                        label={t("quicksupport.support_content.mail")}
                        fullWidth
                        autoComplete="cc-quicksupport-mail"
                        helperText={t("quicksupport.support_content.mail_helper")}
                        variant="standard"
                        value={props.mail}
                        error={ErrorEmail(props.mail)}
                        onChange={e => props.setMail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="quickSupportPhone"
                        label={t("quicksupport.support_content.phone")}
                        helperText={t("quicksupport.support_content.phone_helper")}
                        fullWidth
                        autoComplete="cc-quicksupport-phone"
                        variant="standard"
                        value={props.number}
                        error={ErrorPhone(props.number)}
                        onChange={e => props.setNumber(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        id="quickSupportTitle"
                        label={t("quicksupport.support_content.title")}
                        helperText={t("quicksupport.support_content.title_helper")}
                        fullWidth
                        autoComplete="cc-quicksupport-title"
                        variant="standard"
                        error={props.title.length < 6}
                        value={props.title}
                        onChange={e => props.setTitle(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant={"caption"}>{t("quicksupport.only_de_en")}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        id="quickSupportText"
                        label={t("quicksupport.support_content.text")}
                        fullWidth
                        autoComplete="cc-quicksupport-text"
                        helperText={t("quicksupport.support_content.text_helper")}
                        error={props.text.length < 10}

                        variant="standard"
                        multiline
                        rows={8}
                        value={props.text}
                        onChange={e => props.setText(e.target.value)}
                    />
                </Grid>
                {QuickSupportTypes.find(f => f.type == props.supportType)?.displayPayment && <>

                    <Grid item xs={12}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                control={<Radio
                                    color="secondary"
                                    name="isGuarantee"
                                    checked={props.selectedIsGuarantee == true}
                                    onChange={e => props.setSelectedIsGuarantee(e.target.checked)}

                                />}
                                label={t("quicksupport.support_content.is_guarantee")}
                            />
                            <FormControlLabel
                                control={<Radio color="secondary" name="self_pay"
                                                   checked={props.selectedPaySelf == true}
                                                   onChange={e => props.setSelectedPaySelf(e.target.checked)}
                                />}
                                label={t("quicksupport.support_content.self_pay")}
                            />
                            <FormControlLabel
                                control={<Radio color="secondary" name="owner_pays"
                                                   checked={props.selectedPayOwner == true}
                                                   onChange={e => props.setSelectedPayOwner(e.target.checked)}
                                />}
                                label={t("quicksupport.support_content.owner_pays")}
                            />

                        </RadioGroup>
                    </Grid>
                </>}


                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="saveCard"
                                        checked={props.selectedDSGVO == true}
                                        onChange={e => props.setSelectedDSGVO(e.target.checked)}
                        />}
                        label={t("quicksupport.support_content.dsgvo")}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

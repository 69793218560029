import React, {useEffect, useState} from 'react';
import {TextField, Checkbox, FormControlLabel, Button, CircularProgress, Box, Typography} from '@mui/material';
import FileUpload from "../../../Components/Admin/CoreDumps/FileUpload";
import {useApplicationContext} from "../../../ApplicationContext";
import {useNavigate} from "react-router-dom";
import {BadRequestResponse, GdbServicePing, NotFoundResponse} from "../../../apiClient/Api";
import {green} from "@mui/material/colors";
import ServicesAccordion from "../../../Components/Admin/CoreDumps/ServicesAccordion";

/**
 * Component for uploading and processing core dump files.
 */
const CoreDumpUpload = () => {
    const [response, setResponse] = useState<any>(null);
    const [command, setCommand] = useState<string>('');
    const [interpreter, setInterpreter] = useState<string>('');
    const [buildId, setBuildId] = useState<number | null>(null);
    const [buildType, setBuildType] = useState<string>('');
    const [debug, setDebug] = useState<boolean | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const {api, apiError} = useApplicationContext();
    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);
    const [hasServices, setHasServices] = useState(false);
    const handleFileChange = (file: File | null) => {
        setFile(file)
    };
    const navigate = useNavigate()


    const handleUploadClick = async () => {
        if (file == null)
            return;

        const requestPayload: any = {FileName: file.name};
        setIsUploading(true)
        if (command) requestPayload.Command = command;
        if (interpreter) requestPayload.Interpreter = interpreter;
        if (buildId !== null) requestPayload.BuildId = buildId;
        if (buildType) requestPayload.BuildType = buildType;
        if (debug != null)
            requestPayload.Debug = debug;

        try {
            const res = await api.gdbService.gdbServiceRunGdbProcess(file, requestPayload, {
                //@ts-ignore
                type: "application/octet-stream",
                onUploadProgress: progressEvent => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                },
            });
            setResponse(res.data);
            if (typeof res.data == "string") {
                // Go to coredumps ...
                navigate(`/admin/coredumps/${res.data}`)
            }
        } catch (error) {
            apiError(error)
        } finally {
            setIsUploading(false)
        }
    }
    if (response) {
        return <div>Response: {JSON.stringify(response)}</div>
    }

    return (
        <div style={{display: "flex",flexDirection:"column", justifyContent:"center", alignItems: "center", width: "100%"}}>

            <ServicesAccordion serviceFoundCallback={setHasServices}/>
            {hasServices && <div>
                <FileUpload onFileChange={handleFileChange}/>
                {file && !file.name.endsWith('ioedump') && (
                    <div>
                        <TextField
                            fullWidth
                            label="Command"
                            value={command}
                            onChange={(e) => setCommand(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Interpreter"
                            value={interpreter}
                            onChange={(e) => setInterpreter(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Build ID"
                            type="number"
                            value={buildId ?? ''}
                            onChange={(e) => setBuildId(parseInt(e.target.value, 10) || null)}
                        />
                        <TextField
                            fullWidth
                            label="Build Type"
                            value={buildType}
                            onChange={(e) => setBuildType(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={debug ?? false}
                                    onChange={(e) => setDebug(e.target.checked)}
                                />
                            }
                            label="Debug"
                        />
                        <p>
                            Zusätzliche Informationen sind nur für CoreDumps erforderlich,
                            die nicht mit "ioedump" enden.
                        </p>
                    </div>
                )}


                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleUploadClick}
                    sx={{marginTop: 2}}
                    disabled={(!file || isUploading)}
                >
                    Upload
                    {isUploading && (<CircularProgress
                            variant={"determinate"}
                            value={uploadProgress}
                            size={24}
                            sx={{
                                color: green[500],
                            }}
                        />
                    )}
                </Button>
            </div>}

        </div>
    );
};

export default CoreDumpUpload;

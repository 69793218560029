import { AxiosResponse } from "axios";
import {useContext, useRef, useState} from "react";
import {useApplicationContext} from "../ApplicationContext";

interface DownloadFileProps {
    readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
    readonly preDownloading: () => void;
    readonly postDownloading: () => void;
    readonly onError: () => void;
    readonly getFileName: () => string;
}

interface DownloadedFileInfo {
    readonly download: () => Promise<void>;
    readonly name: string | undefined;
    readonly url: string | undefined;
}

export const useDownloadFile = ({
                                    apiDefinition,
                                    preDownloading,
                                    postDownloading,
                                    onError,
                                    getFileName,
                                }: DownloadFileProps): DownloadedFileInfo => {
    const [url, setFileUrl] = useState<string>();
    const [name, setFileName] = useState<string>();
    const context = useApplicationContext();

    const download = async () => {

        // url


        try {
            preDownloading();
            const { data } = await apiDefinition();
            const url = URL.createObjectURL(new Blob([data]));
            setFileUrl(url);
            setFileName(getFileName());
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', getFileName());
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            link.parentNode?.removeChild(link);
            postDownloading();
            URL.revokeObjectURL(url);
        } catch (error) {
            onError();
        }
    };

    return { download, url, name };
};

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {IconButton, Menu, MenuItem, Box, SxProps, Theme} from '@mui/material';
import {Language} from '@mui/icons-material';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobeEurope} from "@fortawesome/pro-duotone-svg-icons";

interface FloatingLanguageButtonProps {
    floating?: boolean;
    style?: SxProps<Theme>;

}

export default function LanguageButton({style, floating}: FloatingLanguageButtonProps) {
    const {t, i18n} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
        setAnchorEl(null);
    };

    const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setAnchorEl(null);
    };

    const languages = [
        {code: 'de', name: t('base.languages.german')},
        {code: 'en', name: t('base.languages.english')},
        {code: 'es', name: t('base.languages.spanish')},
        {code: 'fr', name: t('base.languages.french')},
    ];

    const defaultStyle: SxProps<Theme> = floating ? {
        position: 'fixed',
        top: '20px',
        right: '20px',
        zIndex: 999
    } : {};

    return (


        <Box sx={{...defaultStyle, ...style}}>
            <IconButton color="primary" aria-label="change language" onClick={handleLanguageMenuOpen}
                        sx={{color: floating ? null : "white"}}>
                <FontAwesomeIcon icon={faGlobeEurope}/>
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleLanguageMenuClose}>
                {languages.map((language) => (
                    <MenuItem key={language.code} onClick={() => handleLanguageChange(language.code)}>
                        {language.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {useApplicationContext} from "../../../ApplicationContext";

export interface IDeleteUserDialogProps {
    id?: string;
    userDeleted: (deleted: boolean) => void;
}

export function DeleteUserDialog(props: IDeleteUserDialogProps) {

    const context = useApplicationContext();
    const [fullName, setFullName] = useState("")
    const [mail, setMail] = useState("")
    const [initialized, setInitialized] = useState(false);
    const reset = () => {
        setInitialized(false)
        setMail("")
        setFullName("")
    }
    const open = props.id != undefined && initialized;
    useEffect(() => {
        if (!initialized && props.id != undefined) {
            setInitialized(true);
            context.api.user.userGetUser(props.id).then(user => {
                if (user.data != undefined) {
                    setFullName(`${user.data.name ?? ""} ${user.data.anrede ?? ""}`)
                    setMail(user.data.eMail ?? "")
                }
            }).catch(context.apiError)
        }
    }, [props.id, initialized])

    const handleClose = () => {
        props.userDeleted(false);
        reset();

    };




    const handleDelete = () => {
        if (props.id) {
            context.api.user.userDeleteUser(props.id).then(() => {

                props.userDeleted(true);
                reset();

            })
                .catch(context.apiError)

        }
    }

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Nutzer löschen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten sie den Nutzer {fullName} ({mail}) wirklich löschen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button onClick={handleDelete} autoFocus>
                    Löschen
                </Button>
            </DialogActions>
        </Dialog>

    );
}

import React, {useState, useEffect} from 'react';
import {
    Collapse,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar,
    Drawer as MuiDrawer
} from "@mui/material";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faServer,
    faChartTreeMap,
    faTimelineArrow,
    faUsers,
    faQrcode,
    faKey,
    faArrowUp,
    faDatabase,
    faFileCircleQuestion,
    faGavel,
    faFilters,
    faCaretDown,
    faCaretUp,
    faChevronLeft
} from '@fortawesome/pro-solid-svg-icons'
import {EUserGroup, GetProductsData} from 'apiClient/Api';
import {RouterLink} from "Components/RouterLink"
import {useTranslation} from 'react-i18next';
import {useApplicationContext} from "ApplicationContext";

const drawerWidth = 240;


const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(6),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7),
                },
            }),
        },
    }),
);

interface MyDrawerProps {
    open: boolean;
    handleDrawerToggle: () => void;
    isMobile: boolean;
}

const SupportDrawer: React.FC<MyDrawerProps> = ({open, handleDrawerToggle, isMobile}) => {
    const context = useApplicationContext();
    const [openVersions, setOpenVersions] = useState(false);
    const [products, setProducts] = useState<GetProductsData[]>([]);

    useEffect(() => {
            context.api.products.productsGetProducts().then(data => {
                if(data && data.data)
                    setProducts(data.data)
            })
    }, []);

    const handleClickVersions = () => {
        setOpenVersions(!openVersions);
    };

    const {t} = useTranslation();

    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <img src={"/logo-2048.png"} height={30}/>
                <IconButton onClick={handleDrawerToggle}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </IconButton>
            </Toolbar>
            <Divider/>
            {context.userData &&
                <List component="nav">
                    <RouterLink to={"/"} text={t("base.pages.dashboard")}
                                icon={<FontAwesomeIcon icon={faChartTreeMap}/>}/>

                    <ListItemButton onClick={handleClickVersions}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faTimelineArrow}/>
                        </ListItemIcon>
                        <ListItemText primary={t("base.pages.versions")}/>
                        {openVersions ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                    </ListItemButton>
                    <Collapse in={openVersions} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {products.map(product =>
                                <RouterLink to={"/versions/" + product.link!}
                                            text={product.name ?? ""}
                                            icon={<FontAwesomeIcon icon={faServer}/>}/>)}
                        </List>
                    </Collapse>

                    {context.userData!.userGroup! >= EUserGroup.ADMIN &&
                        <RouterLink to={"/terms"}
                                    text={t("base.pages.terms")}
                                    icon={<FontAwesomeIcon icon={faGavel}/>}/>}
                    {context.userData!.userGroup! <= EUserGroup.OFFICE &&
                        <>
                            <Divider sx={{my: 1}}/>
                            {!isMobile && <RouterLink to={"/admin/users"}
                                                      text={t("base.pages.users")}
                                                      icon={<FontAwesomeIcon icon={faUsers}/>}/>}
                            {!isMobile && <RouterLink to={"/admin/supportcontracts"}
                                                      text={t("base.pages.supportContracts")}
                                                      icon={<FontAwesomeIcon icon={faQrcode}/>}/>}
                            {!isMobile && <RouterLink to={"/admin/coredumps"}
                                                      text={"Core Dumps"}
                                                      icon={<FontAwesomeIcon icon={faQrcode}/>}/>}
                            {context.userData!.userGroup! <= EUserGroup.DEVELOPMENT &&
                                <>
                                    <RouterLink to={"/admin/password_generator"}
                                                text={t("base.pages.passwordGenerator")}
                                                icon={<FontAwesomeIcon icon={faKey}/>}/>
                                    <ListItemButton onClick={handleClickVersions}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faArrowUp}/>
                                        </ListItemIcon>
                                        <ListItemText primary={t("base.pages.versions")}/>
                                        {openVersions ? <FontAwesomeIcon icon={faCaretUp}/> :
                                            <FontAwesomeIcon icon={faCaretDown}/>}
                                    </ListItemButton>
                                    <Collapse in={openVersions} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {products.map(product =>
                                                <RouterLink to={"/admin/versions/" + product.link!}
                                                            text={product.name ?? ""}
                                                            icon={<FontAwesomeIcon icon={faServer}/>}/>)}
                                        </List>
                                    </Collapse>

                                    {context.userData!.userGroup! <= EUserGroup.ADMIN && !isMobile &&
                                        <>
                                            <RouterLink to={"/admin/systems"}
                                                        text={t("base.pages.systems")}
                                                        icon={<FontAwesomeIcon icon={faDatabase}/>}/>
                                            <RouterLink to={"/admin/filetypes"}
                                                        text={t("base.pages.fileTypes")}
                                                        icon={<FontAwesomeIcon icon={faFileCircleQuestion}/>}/>
                                            <RouterLink to={"/admin/terms"}
                                                        text={t("base.pages.terms")}
                                                        icon={<FontAwesomeIcon icon={faGavel}/>}/>
                                            <RouterLink to={"/admin/filterTags"}
                                                        text={t("base.pages.fileFilter")}
                                                        icon={<FontAwesomeIcon icon={faFilters}/>}/>
                                        </>
                                    }
                                </>
                            }
                            <Divider sx={{my: 1}}/>
                        </>
                    }
                </List>
            }
        </Drawer>
    );
}

export default SupportDrawer;

import {createSearchParams, redirect, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {useApplicationContext} from "../ApplicationContext";
import {da} from "date-fns/locale";
import {GetServiceData} from "../apiClient/Api";
import {Box, Button, FormControl, Paper, TextField, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Label} from "@mui/icons-material";
import {Simulate} from "react-dom/test-utils";
import copy from "copy-to-clipboard";
import {Buffer} from "buffer";



type ServiceCodeRouteParams = {
    si: string
    hw: string
    sw: string;
    st: string
    ts: number
    vf: number
    e: string
    f: string
    q:string

}

const decodeBase64 = (data: string) => {
    return Buffer.from(data, 'base64').toString('ascii');
}

const encodeBase64 = (data: string) => {
    return Buffer.from(data).toString('base64');
}


const defaultRouteParams : ServiceCodeRouteParams = {
    e: "",
    f: "",
    hw: "",
    q: "",
    si: "",
    st: "",
    sw: "",
    ts: 0,
    vf: 0
}


const useNavigateSearch = () => {
    const navigate = useNavigate();
    return (pathname : string, params : any) =>
        navigate({ pathname, search: `?${createSearchParams(params)}` });
};


export const ServiceCode = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const context = useApplicationContext();
    const [st, setSet] = useState("");
    const theme = useTheme();
    const [serviceData, setServiceData] = useState<GetServiceData | undefined>()
    const [routeParams, setRouteParams ]= useState<ServiceCodeRouteParams>();
    const navigate = useNavigateSearch();

    useEffect(() => {




        var q = searchParams.get("q") ?? "";
        let routeParamsLoc : ServiceCodeRouteParams | undefined;
        if(q.length > 0){
            routeParamsLoc =  {...defaultRouteParams , ...JSON.parse(decodeBase64(q))};
        }

        else{
             routeParamsLoc = {
                e: searchParams.get("si") ?? "",
                f: searchParams.get("f") ?? "",
                hw: searchParams.get("hw") ?? "",
                si: searchParams.get("si") ?? "",
                st: searchParams.get("st") ?? "",
                ts: parseInt(searchParams.get("ts") ?? ""),
                vf: parseInt(searchParams.get("vf") ?? ""),
                sw: searchParams.get("sw") ?? "",
                q: searchParams.get("sw") ?? "",
            }
        }


        var qs = searchParams.get("qs");
        if(qs == "true"){
            navigate("/quicksupport", routeParamsLoc)
        }



        if(routeParamsLoc == null)
            return;
        setRouteParams(routeParamsLoc);
        if (routeParamsLoc.st != st) {
            setSet(routeParamsLoc.st);
            context.api.service.serviceGeneratePassword(routeParamsLoc).then(data => {
                if(data && data.data) {
                    setServiceData(data.data);
                }
            }).catch(context.apiError);
        }



    }, [searchParams]);


    return <>
        <Box width={1}>
            <Paper>
                <Box width={0.8} marginLeft={"10%"} paddingTop={5} marginTop={3} paddingBottom={5}>
                    <Typography variant={"h5"}>Passwort Generator</Typography>
                    <Typography variant={"caption"}>Passwort</Typography>
                    <TextField value={serviceData?.password} margin="none" fullWidth

                               color={"primary"} sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.text.primary,
                        },
                    }}/>

                    <Button variant={"outlined"} style={{marginTop: 10}} onClick={(e: SyntheticEvent) => {
                        e.preventDefault();
                        copy(serviceData?.password ?? "")

                    }} fullWidth>In Zwischenablage kopieren</Button>
                    {(serviceData?.supportId?.length ?? 0) > 0 && <>
                        <Typography variant={"caption"}>Support ID</Typography>
                        <TextField value={serviceData?.supportId} margin="none" fullWidth
                                   disabled/></>}
                    {(serviceData?.hardwareVersion?.length ?? 0) > 0 && <>
                        <Typography variant={"caption"}>Hardware</Typography>
                        <TextField value={serviceData?.hardwareVersion} margin="none" fullWidth
                                   disabled/>
                    </>
                    }
                    {(serviceData?.softwareVersion?.length ?? 0) > 0 && <>
                        <Typography variant={"caption"}>Software Version</Typography>
                        <TextField value={serviceData?.softwareVersion} margin="none" fullWidth
                                   disabled/>
                    </>
                    }
                    {(serviceData?.timestamp ?? 0) > 0 && <>
                        <Typography variant={"caption"}>Zeitstempel</Typography>
                        <TextField value={serviceData?.timestamp} margin="none" fullWidth disabled/>
                    </>
                    }

                    <Button style={{marginTop: 30}} href={"/admin/password_generator"} fullWidth color={"warning"}>Neues
                        Passwort generieren</Button>

                </Box>
            </Paper>
        </Box>
    </>

}

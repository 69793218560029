import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel,
    NativeSelect,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {useApplicationContext} from "../../../ApplicationContext";
import {useSnackbar} from "notistack";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";

export interface IAddSupportContractComponents {
    open: boolean
    closeComponent: (created: boolean) => void
    supportId: string | undefined
}


export const AddSupportContractComponent = (props: IAddSupportContractComponents) => {

    const [initialized, setInitialized] = useState(false);
    const [title, setTitle] = useState<string>("");
    const [customer,setCustomer] = useState<string>("");

    const [expire, setExpire] = useState<Dayjs | null>(dayjs().add(1,'year'));
    const [comment,setComment] = useState<string>("")
    const [contractId, setContractId] = useState<string>("")
    const [customerId, setCustomerId] = useState<string>("")
    const context = useApplicationContext();


    const {enqueueSnackbar} = useSnackbar();
    const reset = () => {
        setTitle("")
        setTitle("")
        setCustomer("")
        setComment("")
        setContractId("")
        setCustomerId("")
        setExpire(dayjs().add(1,'year'))
        setInitialized(false);
    }

    useEffect(() => {
        if(props.supportId != undefined && !initialized){

            context.api.supportContract.supportContractGetSupportContract(props.supportId).then(result => {
                const data = result.data
                setExpire(dayjs(data.expire!.toString()))
                setCustomer(data.customerName!)
                setTitle(data.title!)
                setComment(data.comment!)
                setContractId(data.contractId?? "")
                setCustomerId(data.customerId ?? "")

            }).catch(context.apiError)
            setInitialized(true);
            setInitialized(true);
        }
    })



    function handleClose() {
        props.closeComponent(false);
        reset()

    }

    function handleSave() {

        // Update support contract
        if(props.supportId !== undefined){
            props.closeComponent(false);
            reset()
        }
        // Create new support contract
        else{
            context.api.supportContract.supportContractAddSupportContract({
                fileTags: [],
                versionTags: [],
                title: title,
                customerName: customer,
                comment: comment,
                expire: expire?.toISOString()
            }).then(() => {
                props.closeComponent(false);
                reset()
            }).catch(context.apiError)
        }






    }

    return <Dialog open={props.open} onClose={handleClose}>
        {props.supportId == undefined? <DialogTitle>Support Vertrag erstellen</DialogTitle> : <DialogTitle>Support Vertrag editieren</DialogTitle>}
        <DialogContent>
            <DialogContentText>Support Vertrag erstellen oder bearbeiten </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Interne ID"
                type="text"
                fullWidth
                variant="standard"
                disabled={true}
                value={props.supportId ?? "Wird automatisch gesetzt"}
            />
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Wartungsvertrag Nummer"
                type="text"
                fullWidth
                variant="standard"
                disabled={props.supportId != null}
                value={contractId}
            />


            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Debitoren Nummer"
                type="text"
                fullWidth
                variant="standard"
                disabled={props.supportId != null}
                value={customerId}
            />
            <TextField
                margin="dense"
                id="title"
                label="Titel"
                type="text"
                fullWidth
                variant="standard"
                value={title}
                onChange={event => setTitle(event.target.value)}

            />
            <TextField
                margin="dense"
                id="customer"
                label="Kunde"
                type="text"
                fullWidth
                variant="standard"
                value={customer}
                onChange={event => setCustomer(event.target.value)}

            />
            <TextField
                margin="dense"
                id="comment"
                label="Kommentar"
                type="text"
                fullWidth
                variant="standard"
                value={comment}
                onChange={event => setComment(event.target.value)}

            />
            <DatePicker value={expire} onChange={(newValue) => setExpire(newValue)}   />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Abbrechen</Button>
            <Button onClick={handleSave}>Speichern</Button>
        </DialogActions>
    </Dialog>
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * HTTP Return value for host system data
 *
 */
export interface GetHostSystemData {
  /**
   * Internal id of the host system
   *
   */
  id?: string;
  /**
   * Public name
   * BosCompakt
   *
   */
  name?: string;
  /**
   * Minimum OS Version
   *
   */
  minVersion?: string;
  /**
   * Maximum OS Version
   *
   */
  maxVersion?: string;
  /**
   * Short description (for internal use)
   *
   */
  description?: string;
  /**
   * List of used filter tags for current host system
   *
   */
  filterTags?: GetFilterTagData[];
}

/**
 * HTTP return value for filter tags
 *
 */
export interface GetFilterTagData {
  /**
   * Individual identifier of the filter tag. Will be set by user
   *
   * @example "con_BosCompact"
   */
  identifier?: string;
  /**
   * Custom name to describe filter tag
   *
   * @example "BosCompact"
   */
  name?: string;
}

export interface AddHostSystemData {
  name?: string;
  minVersion?: string;
  maxVersion?: string | null;
  description?: string;
}

export interface GetFileTypeData {
  id?: string;
  name?: string;
  description?: string;
  hostSystemName?: string;
  hostSystemId?: string;
  matchingFiles?: string[] | null;
  /**
   * HTTP Return value for host system data
   *
   */
  hostSystem?: GetHostSystemData;
  /**
   * List of used filter tags for current host system
   *
   */
  filterTags?: GetFilterTagData[];
}

export interface AddFileTypeData {
  /**
   * Individual ID of Host system where file type belongs to
   *
   */
  hostSystemId?: string;
  /**
   * Common name of the file type
   *
   */
  name?: string;
  /**
   * Description of the file type
   *
   */
  description?: string;
  /**
   * Used filter tags for current file
   *
   */
  filterTags?: string[];
}

/**
 * Represents a Bad Request Response.
 *
 */
export interface BadRequestResponse {
  /**
   * The HTTP Status code of the bad request.
   *
   * @format int32
   * @example 400
   */
  statusCode?: number;
  /**
   * Custom defined error message.
   *
   * @example "Invalid access rights"
   */
  message?: string;
  /**
   * Stack trace of the error if provided.
   *
   */
  stackTrace?: string | null;
}

/**
 * HTTP Post data to add new filter tag
 *
 */
export interface AddFilterTagData {
  /**
   * Individual identifier of the filter tag. Will be set by user
   *
   * @example "con_BosCompact"
   */
  identifier?: string;
  /**
   * Custom name to describe filter tag
   *
   * @example "BosCompact"
   */
  name?: string;
}

export interface GdbServicePing {
  serviceName?: string;
  buildType?: string[];
  serverAddress?: string;
}

export interface GdbMiRecord {
  typeDiscriminator?: string;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface GetCoreDumpDTO {
  /**
   * Individual ID. Also filename on storage
   * Filename+Extension = Filename on Storage
   *
   * @format guid
   */
  id?: string;
  /**
   * The build number of the software.
   *
   * @format int32
   */
  buildId?: number;
  buildPipeline?: string;
  /**
   * Defines if the software is running in debug mode.
   *
   */
  debug?: boolean;
  /**
   * The OS name of the machine running the software.
   *
   */
  osName?: string;
  /**
   * The OS release of the machine running the software.
   *
   */
  osRelease?: string;
  /**
   * The platform the software is running on.
   *
   */
  platform?: string;
  /** Navigation property to the SupportCode */
  supportCode?: GetSupportCodeData | null;
  /**
   * The system name of the machine running the software.
   *
   */
  systemName?: string;
  /**
   * The version of the software.
   *
   */
  version?: GetVersionData | null;
  /**
   * Parameter if CoreDumpService sucessfully finished the coredump
   *
   * @format date-time
   */
  sucessfullyFinished?: string | null;
  /**
   * Parameter if CoreDumpService sucessfully finished the coredump
   *
   * @format date-time
   */
  created?: string | null;
  /** File deleted from storage */
  isDeletedFromStorage?: boolean;
  /**
   * Comment to the core dump by the service team
   *
   */
  serviceComment?: string;
  /** Error while handling GDB Data */
  error?: string | null;
  /** Not mapped colum to Serialize Metadata */
  metadata?: any;
}

/**
 * HTTP Return value for support code data
 *
 */
export interface GetSupportCodeData {
  /**
   * Support code id
   *
   */
  id?: string;
  /**
   * Date time when support code was created
   *
   * @format date-time
   */
  createdAt?: string;
  /**
   * Date time when support code was updated
   *
   * @format date-time
   */
  updatedAt?: string | null;
  /**
   * Support ID (will be generated by system)
   * AC3-B7Q
   *
   */
  supportId?: string;
  /**
   * Redirect url, where support code should redirect to
   *
   */
  redirectUrl?: string;
  /**
   * Name of the support code (can be used to describe the system)
   *
   */
  name?: string;
  /**
   * Generated url to generate QR code
   *
   *     https://support.reichert-gmbh.com/quicksupport?data=niubadifhb201823biadjbcoSDIUBF
   *
   *
   */
  customUrl?: string;
}

/**
 * HTTP GET of internal version data
 *
 */
export type GetVersionData = GetVersionDataCustomer & {
  /**
   * Internal information for office / development in company
   *
   */
  internalInformation?: string;
};

/**
 * HTTP GET of customer based version data
 *
 */
export type GetVersionDataCustomer = GetVersionDataPublic & {
  /**
   * Short description of the version
   *
   */
  description?: string;
  /** Header of version */
  header?: string | null;
  /** Footer of version */
  footer?: string | null;
  /**
   * Version stage. Used to display version stage in UI
   *
   */
  stage?: EVersionStage;
  /**
   * Summary of critical version. If empty current version is NOT a critical version.
   *
   */
  criticalUpdate?: string | null;
  /**
   * List of downloadable files
   *
   */
  files?: VersionVersionFileData[];
};

/**
 * Version Stage
 *
 */
export enum EVersionStage {
  INTERNAL = 0,
  ALPHA = 1,
  BETA = 2,
  RELEASE = 3,
  APPROVED = 4,
}

export interface VersionVersionFileData {
  id?: string;
  fileName?: string;
  type?: GetFileTypeData;
  downloadedBy?: GetUserDataShort[];
}

/**
 * Short user data e.G. for list of users which downloaded a file
 *
 */
export interface GetUserDataShort {
  /**
   * UUID of the User
   *
   * @format guid
   */
  id?: string;
  /**
   * Email of the user
   *
   */
  name?: string;
  /**
   * Email of the user
   *
   */
  eMail?: string;
}

/**
 * HTTP GET of public version data
 *
 */
export interface GetVersionDataPublic {
  /**
   * Version ID as computed string.
   *
   */
  versionId?: string;
  /**
   * Major version as number
   *
   * @format int32
   */
  major?: number;
  /**
   * Minor version as number
   *
   * @format int32
   */
  minor?: number;
  /**
   * Patch version as number
   *
   * @format int32
   */
  patch?: number;
  /**
   * Subtitle of the version (first line to describe content)
   *
   */
  subtitle?: string;
  /**
   * Version changes as object
   *
   */
  changes?: VersionChanges;
  /**
   * DateTime when version was approved
   *
   * @format date
   */
  approved?: string | null;
  /** Cild versions of the current version. Can also be null */
  childVersions?: GetVersionData[] | null;
  /** Id of product connected to this version */
  productId?: string;
}

/**
 * Version changes structure for storage as JSON in Databaseö
 *
 */
export interface VersionChanges {
  /**
   * List of bugfixes as list of strings
   *
   */
  bugfixes?: string[];
  /**
   * List of features as list of strings
   *
   */
  features?: string[];
  /**
   * List of highlights as list of strings
   *
   */
  highlights?: string[];
}

/** DTO for returning products */
export interface GetProductsData {
  /**
   * Id of Product
   * @format guid
   */
  id?: string;
  /** Public link to find Product */
  link?: string;
  /**
   * Individual name of Product
   * @example "Zentrale Bedieneinrichtung (ZBE)
   *             "
   */
  name?: string;
  /**
   * Description of Product
   * @example "Zentrale Bedieneinrichtung (ZBE) für die Feuerwehr
   *             "
   */
  description?: string;
}

/** Holds data related to a file. */
export interface FileDataResponse {
  /**
   * The unique identifier for the file, which is also used as the filename in storage.
   * @format guid
   * @example "3D290374-8689-4856-B5C4-35E0414F66E8"
   */
  id?: string;
  /**
   * The original name of the file before it was uploaded to the support system.
   * @example "AfVis-6.3.12(100)-Setup.exe"
   */
  fileName?: string;
  /** The version to which the file belongs. */
  version?: GetVersionDataCustomer | null;
  /** The product to which the file belongs. */
  product?: GetProductsData | null;
  /**
   * The file's extension.
   * @example ".exe"
   */
  extension?: string;
  /** The type of the file, used for filtering. */
  type?: GetFileTypeData;
  /**
   * Indicates if the file is a debug version. Debug versions are only available for support purposes.
   * @example false
   */
  debugVersion?: boolean;
  /**
   * The file's MIME type, used for downloading the file from storage.
   * @example "application/pdf"
   */
  mimeType?: string;
}

export interface FileUploadHeader {
  /**
   * The major version number.
   * @format int32
   * @example 3
   */
  major?: number;
  /**
   * The minor version number.
   * @format int32
   * @example 1
   */
  minor?: number;
  /**
   * The patch version number.
   * @format int32
   * @example 0
   */
  patch?: number;
  /**
   * The unique identifier for the product.
   * @format guid
   * @example "3D290374-8689-4856-B5C4-35E0414F66E8"
   */
  productId?: string;
  /**
   * FileTypeId of FileType which belongs to this file
   * @format guid
   */
  fileTypeId?: string;
  /** Filename of the file uploaded */
  fileName?: string;
  /** File which will be uploaded */
  contentType?: string;
}

/**
 * HTTP get type for generating password  data
 *
 */
export interface GetServiceData {
  /**
   * Support in given format.
   * Can be used to check if system has current support contract
   * ABC2-B7J1
   *
   */
  supportId?: string | null;
  /**
   * Generated password
   *
   */
  password?: string;
  /**
   * System software version
   *
   */
  softwareVersion?: string | null;
  /**
   * System timestamp
   *
   * @format int32
   */
  timestamp?: number | null;
  /**
   * System hardware version
   *
   */
  hardwareVersion?: string | null;
}

/**
 * Set of url parameters to find correct support contract and generate password
 *
 */
export interface ResolveServiceData {
  /**
   * Support ID
   *
   * @example "HQXBX-BU8PB"
   */
  si?: string | null;
  /**
   * Software Version
   *
   * @example "6.3.10"
   */
  sw?: string | null;
  /**
   * Hardware
   *
   * @example "-"
   */
  hw?: string | null;
  /**
   * Service Token
   *
   * @example "30cd-1907-26a4"
   */
  st?: string;
  /**
   * Remaining Time
   *
   * @format int32
   * @example 0
   */
  vf?: number | null;
  /**
   * Timestamp
   *
   * @format int32
   * @example 43200
   */
  ts?: number | null;
}

/**
 * HTTP Return value for support code data
 *
 */
export interface GetQuickSupportData {
  /**
   * Support code id
   *
   */
  id?: string;
  /**
   * Support code id
   *
   */
  isValid?: boolean;
  /**
   * Name of the support code (can be used to describe the system)
   *
   */
  name?: string;
  /**
   * Contract number
   *
   */
  contractContractId?: string;
  /**
   * Contract title
   *
   */
  contractTitle?: string;
  contractExpire?: string;
  contractInfinite?: boolean;
}

/**
 * Bad request reponse objece
 *
 */
export interface NotFoundResponse {
  /**
   * HTML Status code
   *
   * @format int32
   * @example 404
   */
  statusCode?: number;
  /**
   * Self defined message (e.G. kein login möglich)
   *
   * @example "Der Nutzer wurde nicht gefunden"
   */
  message?: string;
}

/**
 * HTTP Post type for adding support code data
 *
 */
export interface AddSupportCodeData {
  /**
   * Support ID
   *
   */
  supportId?: string;
  /**
   * System name
   *
   */
  name?: string;
  /**
   * URL to Redirect to
   *
   */
  redirectUrl?: string;
}

/**
 * HTTP Return value for support contract data
 *
 */
export interface GetSupportContractData {
  id?: string;
  /**
   * Short title where Support Contract will belong to (e.G. ELW1 JUH BONN)
   *
   */
  title?: string;
  /**
   * Name of the customer where Support Contract belongs to (e.G. JUH BONN)
   *
   */
  customerName?: string;
  /**
   * Date and Time on which the Support Contract was created
   *
   * @format date-time
   */
  created?: string;
  /**
   * DateTime on which Support ID will expire.
   * Default expiration date: Now + 1 Year
   *
   * @format date
   */
  expire?: string;
  /**
   * Comment which can be added to support contract
   *
   */
  comment?: string;
  /**
   * Contract ID of Customer Relationship Management
   *
   */
  contractId?: string;
  /**
   * Customer ID of Customer Relationship Management
   *
   */
  customerId?: string;
  /**
   * Filter tags for the current support contract
   *
   */
  filterTags?: GetFilterTagData[];
  /**
   * Infinite contract
   *
   */
  infinite?: boolean;
  /**
   * Number of FilterTags for current contract
   * @format int32
   */
  filterTagsCount?: number;
  /** Get data of support contracts to display in UI */
  supportCodes?: GetQuickSupportData[];
  /**
   * Support contract status between 0 (expired) and 3 (long term)
   *
   *
   *         3At least 3 years
   *
   *
   *         2At least 60 days
   *
   *
   *         1Valid but less then 60 days
   *
   *
   *         0Expired
   *
   *
   *
   * @format int32
   */
  status?: number;
}

/**
 * HTTP Post to create new contact in contact system
 *
 */
export interface CreateContactData {
  /**
   * Support contact title
   *
   */
  title?: string;
  /**
   * Support contact content
   *
   */
  content?: string;
  /**
   * Support Contract ID where contact is belonging to
   *
   */
  contract?: string;
  /**
   * Support or new offer
   *
   */
  isSupport?: boolean;
  /**
   * Service ID of the corresponding system
   *
   */
  serviceId?: string;
  /**
   * Type of the contact. Default is 1 (Keine Angabe)
   * @format int32
   */
  contactType?: number;
}

/**
 * HTTP Post type for adding support contract data
 *
 */
export interface AddSupportContractData {
  /**
   * File Types which current support contract can access
   *
   */
  fileTags?: string[];
  /**
   * Version Tags which current support contract can access
   *
   */
  versionTags?: string[];
  /**
   * Short title where Support Contract will belong to (e.G. ELW1 JUH BONN)
   *
   */
  title?: string;
  /**
   * Name of the customer where Support Contract belongs to (e.G. JUH BONN)
   *
   */
  customerName?: string;
  /**
   * Date and Time on which the Support Contract was created
   *
   * @format date
   */
  expire?: string | null;
  /**
   * Comment which can be added to support contract
   *
   */
  comment?: string;
}

/**
 * HTTP GET return type for user
 *
 */
export interface GetUserData {
  /**
   * UUID of the user
   *
   * @format guid
   * @example "cda9cb75-6479-4d05-9560-343935a0800d"
   */
  id?: string;
  /**
   * E-Mail Address of the user
   *
   * @example "max.mustermann@musterstadt.de"
   */
  eMail?: string;
  /**
   * Full name of the user
   *
   * @example "Max Mustermann"
   */
  name?: string;
  /**
   * Saltuation including name of the user
   *
   * @example "Sehr geehrter Herr Max Mustermann,"
   */
  anrede?: string;
  /**
   * Usergroup with specified access rights
   *
   */
  userGroup?: EUserGroup;
  /**
   * Date Time when user was created
   *
   * @format date-time
   * @example "2023-05-17T10:57:30.129177"
   */
  created?: string;
  /**
   * Date time of the last login (e.G. see which users downloaded a file)
   *
   * @format date-time
   * @example "2023-05-17T10:57:30.129177"
   */
  lastLogin?: string | null;
  /**
   * DateTime when Welcome Mail was sent
   *
   * @format date-time
   * @example "2023-05-17T10:57:30.129177"
   */
  sentWelcomeMail?: string | null;
  /**
   * Shows is user is currently locked
   *
   * @example true
   */
  locked?: boolean;
  /**
   * Minimum version stage current user can see
   *
   * @example 3
   */
  minAllowedStage?: EVersionStage;
}

/**
 * User Group Enum
 *
 */
export enum EUserGroup {
  SUPER_ADMIN = 0,
  ADMIN = 1,
  DEVELOPMENT = 2,
  OFFICE = 3,
  CUSTOMER = 4,
}

/** Represents a data transfer object for fetching builds. */
export interface GetBuildsDto {
  /** Gets or sets the Id of the property. */
  id?: string;
  /** Gets or sets the name. */
  name?: string;
  /** Gets or sets the branch. */
  branch?: string;
  /** Gets or sets the URL property. */
  url?: string;
}

/** Represents a DTO (Data Transfer Object) for file download information. */
export interface FileDownloadDto {
  /** Represents the version key of a product. */
  versionKey?: VersionKey;
  /** Gets or sets the build ID of the BuildServer matching the version. */
  buildId?: string;
}

/** Represents the version key of a product. */
export interface VersionKey {
  /**
   * The major version number.
   * @format int32
   * @example 3
   */
  major?: number;
  /**
   * The minor version number.
   * @format int32
   * @example 1
   */
  minor?: number;
  /**
   * The patch version number.
   * @format int32
   * @example 0
   */
  patch?: number;
  /**
   * The unique identifier for the product.
   * @format guid
   * @example "3D290374-8689-4856-B5C4-35E0414F66E8"
   */
  productId?: string;
}

/**
 * HTTP Return value for usage terms data
 *
 */
export interface GetTermsData {
  /**
   * Internal ID of the Terms (for technical purpose)
   *
   * @format guid
   */
  id?: string;
  /**
   * Public Version titpe
   *
   */
  versionTitle?: string;
  /**
   * Content of the Terms. Will be stored in Markdown text
   *
   */
  content?: string;
  /**
   * Date time when Terms where created.
   * Latest date is always the one which is checked by system.
   *
   * @format date-time
   */
  created?: string;
}

/**
 * HTTP POST value for usage terms data
 *
 */
export interface AddTermsData {
  /**
   * Title of the Term to be added (also used to version terms)
   *
   */
  versionTitle?: string;
  /**
   * Content of the Terms. Will be stored in Markdown text
   *
   */
  content?: string;
}

/** Represents user registration data. */
export interface RegisterUserData {
  /**
   * Gets or sets the customer name.
   * @minLength 1
   * @example "John Doe"
   */
  customer: string;
  /**
   * Gets or sets the email address.
   * @format email
   * @minLength 1
   * @example "john.doe@example.com"
   */
  email: string;
  /**
   * Gets or sets the gender of the user.
   * @minLength 1
   */
  sex: string;
  /**
   * Gets or sets the first name of the user.
   * @minLength 1
   * @example "John"
   */
  firstName: string;
  /**
   * Gets or sets the last name of the user.
   * @minLength 1
   * @example "Doe"
   */
  lastName: string;
  /**
   * Gets or sets the support contract ID (if applicable).
   * @example "12345-12345"
   */
  supportContractId?: string | null;
  /**
   * Gets or sets additional information provided by the user.
   * @example "Some additional information"
   */
  furtherInformation?: string | null;
  /**
   * Gets or sets a value indicating whether the user allows receiving extra emails.
   * @example true
   */
  allowExtraMail: boolean;
  /**
   * Gets or sets a value indicating whether the user allows saving data.
   * @example true
   */
  allowSaveData: boolean;
  /**
   * Gets or sets the ReCaptcha token for validation.
   * @minLength 1
   */
  reCaptchaToken: string;
}

/**
 * Return data for sign in process
 *
 */
export interface SignInUserData {
  /**
   * User E-Mail
   *
   */
  eMail?: string;
  /**
   * Username
   *
   */
  name?: string;
  /**
   * User Group
   *
   */
  userGroup?: EUserGroup;
  /**
   * Need change password after login
   *
   */
  needChangePassword?: boolean;
  /**
   * Authentication token
   *
   */
  token?: string;
}

/**
 * HTTP POST type to add new user
 *
 */
export interface AddUserData {
  /**
   * E-Mail (used for login)
   *
   */
  eMail?: string;
  /**
   * Full name of the user
   *
   */
  name?: string;
  /**
   * Salutation including the name
   *
   */
  anrede?: string;
  /**
   * User group with access rights
   *
   */
  userGroup?: EUserGroup;
  /**
   * Minimum version stage current user can see
   *
   */
  minAllowedStage?: EVersionStage;
  /**
   * First password (temp password)
   *
   */
  password?: string;
  /**
   * Repeated first password (temp password) to ensure password is correct
   *
   */
  passwordRepeat?: string;
}

/**
 * HTTP POST type to edit existing user
 *
 */
export interface EditUserData {
  /**
   * New full name of the user
   *
   */
  name?: string | null;
  /**
   * New salutation including name
   *
   */
  anrede?: string | null;
  /**
   * New UserGroup
   *
   */
  userGroup?: EUserGroup | null;
  /**
   * Minimum version stage current user can see
   *
   */
  minAllowedStage?: EVersionStage | null;
  /**
   * optional new password
   *
   */
  password?: string | null;
  /**
   * optional new password repeat
   *
   */
  passwordRepeat?: string | null;
}

/**
 * HTTP POST to change own user
 *
 */
export interface ChangePasswordData {
  /**
   * Current used password (can be temp password or current password)
   *
   */
  currentPassword?: string;
  /**
   * New user password
   *
   */
  newPassword?: string;
  /**
   * New repeated password
   *
   */
  newPasswordRepeat?: string;
}

export interface GetSupportUserData {
  /**
   * UUID of the user
   *
   * @format guid
   * @example "cda9cb75-6479-4d05-9560-343935a0800d"
   */
  id?: string;
  /**
   * E-Mail Address of the user
   *
   * @example "max.mustermann@musterstadt.de"
   */
  eMail?: string;
  /**
   * Full name of the user
   *
   * @example "Max Mustermann"
   */
  name?: string;
  /**
   * Saltuation including name of the user
   *
   * @example "Sehr geehrter Herr Max Mustermann,"
   */
  anrede?: string;
  /**
   * Usergroup with specified access rights
   *
   */
  userGroup?: EUserGroup;
  /**
   * Date Time when user was created
   *
   * @format date-time
   * @example "2023-05-17T10:57:30.129177"
   */
  created?: string;
  /**
   * Date time of the last login (e.G. see which users downloaded a file)
   *
   * @format date-time
   * @example "2023-05-17T10:57:30.129177"
   */
  lastLogin?: string | null;
  /**
   * Shows is user is currently locked
   *
   * @example true
   */
  locked?: boolean;
  /**
   * Minimum version stage current user can see
   *
   * @example 3
   */
  minAllowedStage?: EVersionStage;
  /** List all support contracts of the user */
  supportContracts?: GetSupportContractData[];
  /** List all filter tags of the user */
  filterTags?: GetFilterTagData[];
  /** Get terms user accepted */
  acceptedTerms?: GetTermsData | null;
}

/**
 * HTTP POST Data to add new version
 *
 */
export interface AddVersionData {
  /** VersionKey to find specific version */
  versionKey?: VersionKey;
  /**
   * Set version stage for the current version
   *
   * @example "EVersionStage.RELEASE"
   */
  stage?: EVersionStage;
  /**
   * Short version description (name of the version)
   *
   */
  description?: string;
  /**
   * Summary of all changes as VersionChanges object.
   * Can be used for public displaying of the version
   *
   */
  changes?: VersionChanges;
  /**
   * Description for critical updates. If empty current version is NOT a critical version.
   *
   */
  criticalUpdate?: string | null;
  /**
   * Internal information (only for office, development, ...)
   *
   */
  internalInformation?: string;
  /**
   * Date when version was / will be approved
   * @format date
   */
  approved?: string | null;
  /** Header of version */
  header?: string | null;
  /** Footer of version */
  footer?: string | null;
}

/** Data required to check file download permissions before actual download. */
export interface GetFileCheckDownload {
  /**
   * Determines if the file can be downloaded.
   * @example false
   */
  canDownload?: boolean;
  /**
   * Message to be communicated to the user regarding the download.
   * @example "You have exceeded your download limit."
   */
  message?: string;
  /**
   * The maximum allowed major version for download.
   * @format int32
   * @example 1
   */
  maxMajorVersion?: number;
  /**
   * The maximum allowed minor version for download.
   * @format int32
   * @example 0
   */
  maxMinorVersion?: number;
  /**
   * The maximum allowed patch version for download.
   * @format int32
   * @example 0
   */
  maxPatchVersion?: number;
}

export interface GetVersionFileData {
  id?: string;
  /** Represents the version key of a product. */
  versionKey?: VersionKey;
  fileName?: string;
  fileType?: GetFileTypeData;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://localhost:5297" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Support Server
 * @version 99.99.99.9
 * @baseUrl http://localhost:5297
 *
 * Reichert Support Server
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  fileType = {
    /**
     * No description
     *
     * @tags FileType
     * @name FileTypeGetHostSystems
     * @summary Get list of all valid host systems
     * @request GET:/FileType/HostSystems
     * @secure
     */
    fileTypeGetHostSystems: (params: RequestParams = {}) =>
      this.request<GetHostSystemData[], any>({
        path: `/FileType/HostSystems`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method performs a ```DELETE``` request to the host systems database. The host system ID is passed as a parameter. The host system is deleted from the database if it exists. If the host system doesn't exist, an error message is returned.
     *
     * @tags FileType
     * @name FileTypeDeleteHostSystem
     * @summary Asynchronously deletes a host system with a specific ID.
     * @request DELETE:/FileType/HostSystem/{id}
     * @secure
     */
    fileTypeDeleteHostSystem: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/FileType/HostSystem/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description This method performs an asynchronous update operation on the host system and returns an ActionResult of GetHostSystemData. It accepts a string 'id' representing the unique identifier of the host system and an 'AddHostSystemData' object.
     *
     * @tags FileType
     * @name FileTypeUpdateHostSystem
     * @summary Updates an existing host system.
     * @request PUT:/FileType/HostSystem/{id}
     * @secure
     */
    fileTypeUpdateHostSystem: (id: string, data: AddHostSystemData, params: RequestParams = {}) =>
      this.request<GetHostSystemData, any>({
        path: `/FileType/HostSystem/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Post operation is asynchronous and the result of the request returns an ActionResult`1 where TResult is GetHostSystemData.
     *
     * @tags FileType
     * @name FileTypeAddHostSystem
     * @summary Adds a new host system asynchronous.
     * @request POST:/FileType/HostSystem
     * @secure
     */
    fileTypeAddHostSystem: (data: AddHostSystemData, params: RequestParams = {}) =>
      this.request<GetHostSystemData, any>({
        path: `/FileType/HostSystem`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileType
     * @name FileTypeGetFileTypes
     * @summary An HTTP GET endpoint that retrieves a list of file types.
     * @request GET:/FileType/FileType
     * @secure
     */
    fileTypeGetFileTypes: (params: RequestParams = {}) =>
      this.request<GetFileTypeData[], any>({
        path: `/FileType/FileType`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method specifically targets the addition of a new file type into the system. Use this method carefully to avoid duplications or invalid data in the system.
     *
     * @tags FileType
     * @name FileTypeAddFileType
     * @summary This method allows to add a file type.
     * @request POST:/FileType/FileType
     * @secure
     */
    fileTypeAddFileType: (data: AddFileTypeData, params: RequestParams = {}) =>
      this.request<GetFileTypeData, any>({
        path: `/FileType/FileType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method requires the caller to be authorized with the "ADMIN" user group.
     *
     * @tags FileType
     * @name FileTypeUpdateFileTypes
     * @summary Updates the file types for a specific file.
     * @request POST:/FileType/FileType/{id}/FileTypes
     * @secure
     */
    fileTypeUpdateFileTypes: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<GetFileTypeData, any>({
        path: `/FileType/FileType/${id}/FileTypes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This is an asynchronous operation that deletes a specific file type based on the provided ID.
     *
     * @tags FileType
     * @name FileTypeDeleteFileType
     * @summary Deletes a specific file type.
     * @request DELETE:/FileType/FileType/{id}
     * @secure
     */
    fileTypeDeleteFileType: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/FileType/FileType/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description This method will edit existing file type data in the system.
     *
     * @tags FileType
     * @name FileTypeEditFileType
     * @summary Edits the file type data.
     * @request PUT:/FileType/FileType/{id}
     * @secure
     */
    fileTypeEditFileType: (id: string, data: AddFileTypeData, params: RequestParams = {}) =>
      this.request<GetFileTypeData, any>({
        path: `/FileType/FileType/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  filterTags = {
    /**
     * No description
     *
     * @tags FilterTags
     * @name FilterTagsListFilterTags
     * @summary Lists all filter tags
     * @request GET:/FilterTags
     * @secure
     */
    filterTagsListFilterTags: (params: RequestParams = {}) =>
      this.request<GetFilterTagData[], any>({
        path: `/FilterTags`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FilterTags
     * @name FilterTagsAddFilterTag
     * @summary Add new filter tag
     * @request POST:/FilterTags
     * @secure
     */
    filterTagsAddFilterTag: (data: AddFilterTagData, params: RequestParams = {}) =>
      this.request<any, BadRequestResponse>({
        path: `/FilterTags`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  gdbService = {
    /**
     * No description
     *
     * @tags GdbService
     * @name GdbServicePing
     * @summary Gdb Service sends a "Ping" to the SupportServer to show it is alive
     * @request POST:/GdbService/ping
     * @secure
     */
    gdbServicePing: (data: GdbServicePing, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/GdbService/ping`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This endpoint is accessible only from local IP addresses as defined by the "LocalIpPolicy". It allows uploading results for a core dump that has been initiated by the SupportServer.
     *
     * @tags GdbService
     * @name GdbServiceUploadGdbResult
     * @summary Receives and processes GDB MI records associated with a specific core dump.
     * @request POST:/GdbService/{id}/processed
     * @secure
     */
    gdbServiceUploadGdbResult: (id: string, data: GdbMiRecord[], params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/GdbService/${id}/processed`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This endpoint should be called when an error occurs on a machine during the processing of a core dump. It logs the error message in the database associated with the specific core dump ID.
     *
     * @tags GdbService
     * @name GdbServiceLogGdbError
     * @summary Logs an error associated with a specific core dump.
     * @request POST:/GdbService/{id}/processed/error
     * @secure
     */
    gdbServiceLogGdbError: (id: string, data: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/GdbService/${id}/processed/error`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This method requires the "Development" user group authorization.
     *
     * @tags GdbService
     * @name GdbServiceGetServiceBuilds
     * @summary Gets a list of service builds for a given service.
     * @request GET:/GdbService/{name}/builds
     * @secure
     */
    gdbServiceGetServiceBuilds: (name: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/GdbService/${name}/builds`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GdbService
     * @name GdbServiceGetArtifacts
     * @request GET:/GdbService/{name}/builds/{id}/artifacts
     * @secure
     */
    gdbServiceGetArtifacts: (name: string, id: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/GdbService/${name}/builds/${id}/artifacts`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GdbService
     * @name GdbServiceRunGdbProcess
     * @summary Runs a GDB process on the specified GDB service.
     * @request POST:/GdbService/run-gdb-process
     * @secure
     */
    gdbServiceRunGdbProcess: (
      data: File,
      query?: {
        Command?: string | null;
        Interpreter?: string | null;
        FileName?: string;
        /** @format int32 */
        BuildId?: number | null;
        BuildType?: string | null;
        Debug?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, ProblemDetails>({
        path: `/GdbService/run-gdb-process`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GdbService
     * @name GdbServiceListResults
     * @summary Retrieves a list of core dump results.
     * @request GET:/GdbService/results
     * @secure
     */
    gdbServiceListResults: (
      query?: {
        /**
         * If true, only returns results that are marked as successfully finished. Defaults to true.
         * @default true
         */
        onlyFinished?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCoreDumpDTO[], any>({
        path: `/GdbService/results`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is accessible only to users with the DEVELOPMENT user group.
     *
     * @tags GdbService
     * @name GdbServiceGetResultById
     * @summary Gets the core dump result by ID.
     * @request GET:/GdbService/results/{id}
     * @secure
     */
    gdbServiceGetResultById: (id: string, params: RequestParams = {}) =>
      this.request<GetCoreDumpDTO, any>({
        path: `/GdbService/results/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is accessible only to users with the DEVELOPMENT user group.
     *
     * @tags GdbService
     * @name GdbServiceDeleteCoreDumpById
     * @summary Gets the core dump result by ID.
     * @request DELETE:/GdbService/results/{id}
     * @secure
     */
    gdbServiceDeleteCoreDumpById: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/GdbService/results/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description This method checks if the specified ID exists in the CoreDump table and if the corresponding record is marked as finished. It then attempts to read the associated JSON file, which contains the details of the core dump, and returns its content as a list of GdbMiRecord objects.
     *
     * @tags GdbService
     * @name GdbServiceGetResultDataById
     * @summary Retrieves the details of a specific core dump result by its unique identifier.
     * @request GET:/GdbService/results/{id}/data
     * @secure
     */
    gdbServiceGetResultDataById: (id: string, params: RequestParams = {}) =>
      this.request<GdbMiRecord[], any>({
        path: `/GdbService/results/${id}/data`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GdbService
     * @name GdbServiceServices
     * @summary Retrieves the services available.
     * @request GET:/GdbService/services
     * @secure
     */
    gdbServiceServices: (params: RequestParams = {}) =>
      this.request<GdbServicePing[], any>({
        path: `/GdbService/services`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  products = {
    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProducts
     * @summary Get all products.
     * @request GET:/Products
     * @secure
     */
    productsGetProducts: (params: RequestParams = {}) =>
      this.request<GetProductsData[], any>({
        path: `/Products`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProductByLink
     * @summary Get a specific product by its link.
     * @request GET:/Products/Link/{link}
     * @secure
     */
    productsGetProductByLink: (link: string, params: RequestParams = {}) =>
      this.request<GetProductsData, any>({
        path: `/Products/Link/${link}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUploadFileToProduct
     * @summary Uploads a file to a specific product.
     * @request POST:/Products/UploadFile
     * @secure
     */
    productsUploadFileToProduct: (data: File, params: RequestParams = {}) =>
      this.request<FileDataResponse, any>({
        path: `/Products/UploadFile`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetFilesByProductLink
     * @summary Gets all files for a specific product.
     * @request GET:/Products/Files/{productLink}
     * @secure
     */
    productsGetFilesByProductLink: (productLink: string, params: RequestParams = {}) =>
      this.request<FileDataResponse[], any>({
        path: `/Products/Files/${productLink}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  service = {
    /**
     * No description
     *
     * @tags Service
     * @name ServiceGeneratePassword
     * @summary This method is used to generate a password.
     * @request POST:/Service
     * @secure
     */
    serviceGeneratePassword: (data: ResolveServiceData, params: RequestParams = {}) =>
      this.request<GetServiceData, any>({
        path: `/Service`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  supportCode = {
    /**
     * @description This method retrieves all the support codes currently stored in the system. The use of this method should be limited due to its potential impact on system performance.
     *
     * @tags SupportCode
     * @name SupportCodeListSupportCodes
     * @summary Lists the support codes.
     * @request GET:/SupportCode
     * @secure
     */
    supportCodeListSupportCodes: (params: RequestParams = {}) =>
      this.request<GetSupportCodeData[], any>({
        path: `/SupportCode`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This function accepts a data model via HTTP POST method to introduce a new support code into the system. It is vital that the data model contains all necessary details for the creation of a support code. If the provided contract is not found in the system, it will return an EntityNotFound result.
     *
     * @tags SupportCode
     * @name SupportCodeAddSupportCode
     * @summary Creates and adds a new support code into the system.
     * @request POST:/SupportCode
     * @secure
     */
    supportCodeAddSupportCode: (data: AddSupportCodeData, params: RequestParams = {}) =>
      this.request<GetSupportCodeData, NotFoundResponse>({
        path: `/SupportCode`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method is used to quickly get support based on the provided data. It's an asynchronous operation and allows anonymous access.
     *
     * @tags SupportCode
     * @name SupportCodeQuickSupport
     * @summary This is the QuickSupport action method in the Controller
     * @request GET:/SupportCode/quicksupport
     * @secure
     */
    supportCodeQuickSupport: (
      query?: {
        /**
         * The data based on which support is provided. This could be an issue description or relevant
         * code/conditions to troubleshoot.
         *
         */
        data?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetQuickSupportData, NotFoundResponse>({
        path: `/SupportCode/quicksupport`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupportCode
     * @name SupportCodeGetSupportCode
     * @summary An HTTP GET endpoint that retrieves support code data associated with the provided `id`.
     * @request GET:/SupportCode/{id}
     * @secure
     */
    supportCodeGetSupportCode: (id: string, params: RequestParams = {}) =>
      this.request<GetSupportCodeData, NotFoundResponse>({
        path: `/SupportCode/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Use this method to delete the specific support code from the system. This operation will only be successful if the logged-in user is a super admin. Once the support code is deleted, the operation cannot be undone.
     *
     * @tags SupportCode
     * @name SupportCodeDeleteSupportCode
     * @summary Deletes the specified support code.
     * @request DELETE:/SupportCode/{id}
     * @secure
     */
    supportCodeDeleteSupportCode: (id: string, params: RequestParams = {}) =>
      this.request<boolean, BadRequestResponse | NotFoundResponse>({
        path: `/SupportCode/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Sample request: POST /QuickSupport/Create { "Parameter1": "value", "Parameter2": "value" }
     *
     * @tags SupportCode
     * @name SupportCodeCreateQuickSupport
     * @summary Initiates a request for Quick Support
     * @request POST:/SupportCode/QuickSupport/Create
     * @secure
     */
    supportCodeCreateQuickSupport: (
      data: {
        Title?: string;
        Name?: string;
        EMail?: string;
        Phone?: string | null;
        /** @format int32 */
        RequestType?: number;
        SystemId?: string | null;
        CurrentVersion?: string;
        HostId?: string | null;
        SystemName?: string | null;
        SupportContractId?: string | null;
        ReCaptchaToken?: string | null;
        SelectedDSGVO?: boolean;
        SelectedPaySelf?: boolean | null;
        SelectedPayOwner?: boolean | null;
        SelectedIsGuarantee?: boolean | null;
        Text?: string;
        /** @format binary */
        File?: File | null;
        /** @format binary */
        CoreDump?: File | null;
        TestMessage?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, BadRequestResponse>({
        path: `/SupportCode/QuickSupport/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  supportContract = {
    /**
     * No description
     *
     * @tags SupportContract
     * @name SupportContractListSupportContracts
     * @summary An HTTP GET endpoint that retrieves a list of support contracts.
     * @request GET:/SupportContract
     * @secure
     */
    supportContractListSupportContracts: (params: RequestParams = {}) =>
      this.request<GetSupportContractData[], any>({
        path: `/SupportContract`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method is decorated with the HttpPost attribute, indicating that it is intended to handle HTTP POST requests. Any data necessary for the creation of the support contract needs to be included in the request body (as the parameter of type AddSupportContractData).
     *
     * @tags SupportContract
     * @name SupportContractAddSupportContract
     * @summary This method is intended to add a new support contract using the provided data.
     * @request POST:/SupportContract
     * @secure
     */
    supportContractAddSupportContract: (data: AddSupportContractData, params: RequestParams = {}) =>
      this.request<GetSupportContractData, any>({
        path: `/SupportContract`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupportContract
     * @name SupportContractCreateNewContact
     * @summary Create new contact in external system. Contacts need to belong to support contracts
     * @request POST:/SupportContract/contact
     * @secure
     */
    supportContractCreateNewContact: (data: CreateContactData, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/SupportContract/contact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SupportContract
     * @name SupportContractRemewContract
     * @summary Create new contact in external system. Contacts need to belong to support contracts
     * @request POST:/SupportContract/contact/renew
     * @secure
     */
    supportContractRemewContract: (
      query?: {
        contract?: string;
        /** @format int32 */
        duration?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/SupportContract/contact/renew`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This API will return the entire support contract data, including all its related details.
     *
     * @tags SupportContract
     * @name SupportContractGetSupportContract
     * @summary Asynchronously gets the support contract data for a specific id.
     * @request GET:/SupportContract/{id}
     * @secure
     */
    supportContractGetSupportContract: (id: string, params: RequestParams = {}) =>
      this.request<GetSupportContractData, any>({
        path: `/SupportContract/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This API has a DELETE method signature and will respond with a boolean indicating the outcome of the deletion operation.
     *
     * @tags SupportContract
     * @name SupportContractDeleteSupportContract
     * @summary Deletes a support contract.
     * @request DELETE:/SupportContract/{id}
     * @secure
     */
    supportContractDeleteSupportContract: (id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/SupportContract/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method will update the support contract information in the database, including setting a new expiration date. If the contract does not exist, it will return a NotFoundResult.
     *
     * @tags SupportContract
     * @name SupportContractRenewSupportContract
     * @summary Renews a support contract.
     * @request POST:/SupportContract/{id}/Renew
     * @secure
     */
    supportContractRenewSupportContract: (
      id: string,
      query?: {
        /**
         * A DateOnly instance representing the new expiration date of the contract.
         * This value is required.
         *
         * @format date
         */
        expire?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetSupportContractData, any>({
        path: `/SupportContract/${id}/Renew`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description The users returned are only those associated with the specific support contract identified by the given ID. If no such contract exists, or if it has no associated users, the method will return an empty list.
     *
     * @tags SupportContract
     * @name SupportContractGetSupportContractUsers
     * @summary Fetches a list of users associated with a given support contract.
     * @request GET:/SupportContract/{id}/Users
     * @secure
     */
    supportContractGetSupportContractUsers: (id: string, params: RequestParams = {}) =>
      this.request<GetUserData[], any>({
        path: `/SupportContract/${id}/Users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This controller action handles the addition of a user to a specified support contract by their respective identifiers.
     *
     * @tags SupportContract
     * @name SupportContractAddUserToSupportContract
     * @summary Adds a user to the support contract by the provided IDs.
     * @request POST:/SupportContract/{id}/Users/{userid}
     * @secure
     */
    supportContractAddUserToSupportContract: (id: string, userid: string, params: RequestParams = {}) =>
      this.request<GetUserData[], any>({
        path: `/SupportContract/${id}/Users/${userid}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method removes a specific user from a specified support contract.
     *
     * @tags SupportContract
     * @name SupportContractRemoveUserToSupportContract
     * @summary Removes a user from a support contract.
     * @request DELETE:/SupportContract/{id}/Users/{userid}
     * @secure
     */
    supportContractRemoveUserToSupportContract: (id: string, userid: string, params: RequestParams = {}) =>
      this.request<GetUserData[], any>({
        path: `/SupportContract/${id}/Users/${userid}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method executes asynchronously and is accessible via an HTTP GET request.
     *
     * @tags SupportContract
     * @name SupportContractGetSupportCodes
     * @summary Fetches a list of GetSupportCodeData instances associated with a specified ID.
     * @request GET:/SupportContract/{id}/SupportCodes
     * @secure
     */
    supportContractGetSupportCodes: (id: string, params: RequestParams = {}) =>
      this.request<GetSupportCodeData[], any>({
        path: `/SupportContract/${id}/SupportCodes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This function can be accessed using the HttpGET method and the Sync route. The function calls the ReadFromExternal method on the _supportContractService object. This ReadFromExternal method is responsible for reading data from an external source. The function is asynchronous, meaning it runs in the background and does not block the main application thread.
     *
     * @tags SupportContract
     * @name SupportContractSync
     * @summary This function performs synchronization with the external source.
     * @request GET:/SupportContract/Sync
     * @secure
     */
    supportContractSync: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/SupportContract/Sync`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  teamCity = {
    /**
     * No description
     *
     * @tags TeamCity
     * @name TeamCityTeamCityBuilds
     * @request GET:/TeamCity
     * @secure
     */
    teamCityTeamCityBuilds: (params: RequestParams = {}) =>
      this.request<GetBuildsDto[], any>({
        path: `/TeamCity`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeamCity
     * @name TeamCityTeamCityArtifacts
     * @request GET:/TeamCity/{id}
     * @secure
     */
    teamCityTeamCityArtifacts: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/TeamCity/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeamCity
     * @name TeamCityDownloadFile
     * @request POST:/TeamCity/download
     * @secure
     */
    teamCityDownloadFile: (data: FileDownloadDto, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/TeamCity/download`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  terms = {
    /**
     * No description
     *
     * @tags Terms
     * @name TermsGetLatestTerms
     * @summary Description for the GetLatestTerms action method
     * @request GET:/Terms/latest
     * @secure
     */
    termsGetLatestTerms: (params: RequestParams = {}) =>
      this.request<GetTermsData, any>({
        path: `/Terms/latest`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This action takes an AddTermsData object and replaces the existing terms with the new values provided. The new terms data is returned after the operation is completed.
     *
     * @tags Terms
     * @name TermsReplaceTerms
     * @summary Retrieves terms data by replacing existing terms with new ones.
     * @request POST:/Terms
     * @secure
     */
    termsReplaceTerms: (data: AddTermsData, params: RequestParams = {}) =>
      this.request<GetTermsData, any>({
        path: `/Terms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name UserSignUp
     * @summary An HTTP POST endpoint that registers a new user.
     * @request POST:/User/SignUp
     * @secure
     */
    userSignUp: (data: RegisterUserData, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/SignUp`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserAlive
     * @request GET:/User/Alive
     * @secure
     */
    userAlive: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/Alive`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserSignIn
     * @summary An HTTP POST endpoint that allows a new user to sign up for the application.
     * @request POST:/User/SignIn
     * @secure
     */
    userSignIn: (
      query?: {
        /** The username of the user who wants to sign up. */
        username?: string;
        /** The password of the user who wants to sign up. */
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SignInUserData, any>({
        path: `/User/SignIn`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method handles HTTP POST requests at the endpoint: `/Lock/{id}`. It requires the request to be authorized under the User Group named 'OFFICE'.
     *
     * @tags User
     * @name UserLockUser
     * @summary Locks a user by their unique identifier.
     * @request POST:/User/Lock/{id}
     * @secure
     */
    userLockUser: (id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/Lock/${id}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This controller action will return a boolean value determining if the user has accepted the terms.
     *
     * @tags User
     * @name UserAcceptedTerms
     * @summary Checks if current user accepted terms.
     * @request GET:/User/Me/Terms
     * @secure
     */
    userAcceptedTerms: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/Me/Terms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint can be accessed by anyone without the need for authentication. It takes a username as a parameter in HTTP body.
     *
     * @tags User
     * @name UserPasswordForgotten
     * @summary Endpoint to trigger the forgotten password functionality.
     * @request POST:/User/PasswordForgotten
     * @secure
     */
    userPasswordForgotten: (data: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/PasswordForgotten`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method is a POST endpoint that allows users to accept the terms. It uses the _userService object and its AcceptTerms method.
     *
     * @tags User
     * @name UserAcceptTerms
     * @summary Accepts the terms.
     * @request POST:/User/Me/Terms/Accept
     * @secure
     */
    userAcceptTerms: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/User/Me/Terms/Accept`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description This method accepts a POST request and requires authorization from the specified user group. Execute this operation with caution as it directly affects the system users. If the operation encounters a BadRequestException, the bad request response will return with a related message. Any other exceptions will also be handled and result in a bad request response.
     *
     * @tags User
     * @name UserAddUser
     * @summary Adds a new user to the system.
     * @request POST:/User
     * @secure
     */
    userAddUser: (data: AddUserData, params: RequestParams = {}) =>
      this.request<GetUserData, any>({
        path: `/User`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This function requires authorization with User Group Names of type OFFICE It returns an ActionResult containing a list of GetUserData objects It makes use of the UserService to fetch the list of users This method is asynchronous and should be awaited for the task to complete
     *
     * @tags User
     * @name UserListUsers
     * @summary This method is used to list all users
     * @request GET:/User
     * @secure
     */
    userListUsers: (params: RequestParams = {}) =>
      this.request<GetUserData[], any>({
        path: `/User`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserResendFirstLogin
     * @summary Resends first login mail with new password if not already logged in
     * @request POST:/User/{id}/ResendFirstLogin
     * @secure
     */
    userResendFirstLogin: (id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/${id}/ResendFirstLogin`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method is protected by the authorization policy "UserGroupNames.OFFICE".
     *
     * @tags User
     * @name UserEditUser
     * @summary Edits a user by Id.
     * @request PUT:/User/{id}
     * @secure
     */
    userEditUser: (id: string, data: EditUserData, params: RequestParams = {}) =>
      this.request<GetUserData, any>({
        path: `/User/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method takes user's id as parameter and passes it to the user service to delete the user. Only the users in the OFFICE UserGroup have the authorization to delete a user. It returns a boolean value, true indicating deletion was successful and false indicating otherwise.
     *
     * @tags User
     * @name UserDeleteUser
     * @summary Deletes a user from the system.
     * @request DELETE:/User/{id}
     * @secure
     */
    userDeleteUser: (id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description The HTTP Get method is used to get the user data. Only authorized users can invoke this method. The method is bound to the route 'api/controller/{id}' where 'id' is a placeholder for the user's ID. Any further specific behavior, performance considerations or limitations can be placed here.
     *
     * @tags User
     * @name UserGetUser
     * @summary This asynchronous method retrieves user data based on the provided user ID.
     * @request GET:/User/{id}
     * @secure
     */
    userGetUser: (id: string, params: RequestParams = {}) =>
      this.request<GetUserData, any>({
        path: `/User/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Uses the UserService to handle the actual password changing process. The task represents ongoing work. The result of the work is a boolean value that corresponds to the success or failure of the update operation.
     *
     * @tags User
     * @name UserChangePassword
     * @summary Changes the password for a user.
     * @request POST:/User/ChangePassword
     * @secure
     */
    userChangePassword: (data: ChangePasswordData, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/User/ChangePassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method fetches detailed support data tied to a specific user identified by id. For optimal results, ensure that the id parameter is valid and non-null.
     *
     * @tags User
     * @name UserGetSupportUserData
     * @summary Fetches user's support data.
     * @request GET:/User/{id}/SupportData
     * @secure
     */
    userGetSupportUserData: (id: string, params: RequestParams = {}) =>
      this.request<GetSupportUserData, any>({
        path: `/User/${id}/SupportData`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  version = {
    /**
     * @description This method adds a version
     *
     * @tags Version
     * @name VersionAddVersion
     * @summary Adds version
     * @request POST:/Version
     * @secure
     */
    versionAddVersion: (data: AddVersionData, params: RequestParams = {}) =>
      this.request<GetVersionData, any>({
        path: `/Version`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method updates a version
     *
     * @tags Version
     * @name VersionUpdateVersion
     * @summary Updates version
     * @request PUT:/Version
     * @secure
     */
    versionUpdateVersion: (data: AddVersionData, params: RequestParams = {}) =>
      this.request<GetVersionData, ProblemDetails>({
        path: `/Version`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method gets all public versions
     *
     * @tags Version
     * @name VersionGetPublicVersions
     * @summary Gets public versions
     * @request GET:/Version/{link}/public
     * @secure
     */
    versionGetPublicVersions: (link: string, params: RequestParams = {}) =>
      this.request<GetVersionDataPublic[], any>({
        path: `/Version/${link}/public`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method gets all customer versions
     *
     * @tags Version
     * @name VersionGetCustomerVersions
     * @summary Gets customer versions
     * @request GET:/Version/{link}/customer
     * @secure
     */
    versionGetCustomerVersions: (link: string, params: RequestParams = {}) =>
      this.request<GetVersionDataCustomer[], any>({
        path: `/Version/${link}/customer`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method retrieves all versions
     *
     * @tags Version
     * @name VersionGetVersions
     * @summary Gets all versions
     * @request GET:/Version/{link}
     * @secure
     */
    versionGetVersions: (link: string, params: RequestParams = {}) =>
      this.request<GetVersionData[], any>({
        path: `/Version/${link}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method retrieves all versions
     *
     * @tags Version
     * @name VersionGetVersionsFlat
     * @summary Gets all versions as flat list
     * @request GET:/Version/{link}/Flat
     * @secure
     */
    versionGetVersionsFlat: (link: string, params: RequestParams = {}) =>
      this.request<GetVersionData[], any>({
        path: `/Version/${link}/Flat`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This method approves a version
     *
     * @tags Version
     * @name VersionApproveVersion
     * @summary Approve Version
     * @request POST:/Version/approve
     * @secure
     */
    versionApproveVersion: (data: VersionKey, params: RequestParams = {}) =>
      this.request<GetVersionData, ProblemDetails>({
        path: `/Version/approve`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method revokes a version
     *
     * @tags Version
     * @name VersionRevokeVersion
     * @summary Revokes Version
     * @request DELETE:/Version/approve
     * @secure
     */
    versionRevokeVersion: (data: VersionKey, params: RequestParams = {}) =>
      this.request<GetVersionData, ProblemDetails>({
        path: `/Version/approve`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method deletes a specific file
     *
     * @tags Version
     * @name VersionDeleteFile
     * @summary Deletes a file
     * @request DELETE:/Version/File/{id}
     * @secure
     */
    versionDeleteFile: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/Version/File/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description This method will return a file stream, allowing the client to download the file. The type returned is a FileStreamResult. Use the id parameter to specify the desired file. Requires authorization of a user within the CUSTOMER group.
     *
     * @tags Version
     * @name VersionGetFile
     * @summary Gets the file.
     * @request GET:/Version/File/{id}
     * @secure
     */
    versionGetFile: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/Version/File/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Version
     * @name VersionGetFileDetails
     * @summary Get details of single file
     * @request GET:/Version/File/{id}/Details
     * @secure
     */
    versionGetFileDetails: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/Version/File/${id}/Details`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Version
     * @name VersionCheckFileBeforeDownload
     * @summary Check file before download to display error messages
     * @request GET:/Version/File/{id}/CheckDownload
     * @secure
     */
    versionCheckFileBeforeDownload: (id: string, params: RequestParams = {}) =>
      this.request<GetFileCheckDownload, any>({
        path: `/Version/File/${id}/CheckDownload`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Version
     * @name VersionSendMailToUsers
     * @summary Send mail to all users which downloaded a file
     * @request POST:/Version/{major}/{minor}/{patch}/Mail
     * @secure
     */
    versionSendMailToUsers: (major: number, minor: number, patch: number, data: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/Version/${major}/${minor}/${patch}/Mail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This method will validate the request to ensure that it is a valid form-data request and that the Content-Type header contains a boundary. If the request is not valid, an UnsupportedMediaTypeException will be thrown. Any BadRequestException or EntityNotFoundException that are thrown during the processing of the file will be caught and their messages will be returned as the result of the action.
     *
     * @tags Version
     * @name VersionOnPostUpload
     * @summary Handles the upload of a file.
     * @request POST:/Version/File
     * @secure
     */
    versionOnPostUpload: (
      data: File,
      query?: {
        /**
         * The major version number.
         * @format int32
         * @example 3
         */
        Major?: number;
        /**
         * The minor version number.
         * @format int32
         * @example 1
         */
        Minor?: number;
        /**
         * The patch version number.
         * @format int32
         * @example 0
         */
        Patch?: number;
        /**
         * The unique identifier for the product.
         * @format guid
         * @example "3D290374-8689-4856-B5C4-35E0414F66E8"
         */
        ProductId?: string;
        /**
         * FileTypeId of FileType which belongs to this file
         * @format guid
         */
        FileTypeId?: string;
        /** Filename of the file uploaded */
        FileName?: string;
        /** File which will be uploaded */
        ContentType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetVersionFileData, ProblemDetails>({
        path: `/Version/File`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { QuickSupportTypes } from './QuickSupportTypes';
import { useApplicationContext } from '../../ApplicationContext';
import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from "react-i18next";



export type IReviewProps =  {
    ok: boolean
    recaptchaRef: React.RefObject<ReCAPTCHA>
    data: {
        systemId?: string;
        systemName?: string;
        version: string;
        hostId:string;
        type: number
        name: string;
        mail: string;
        number: string;
        title: string;
        text: string
    }
}

export default function Review({ok, data, recaptchaRef}: IReviewProps) {
    var context = useApplicationContext();
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("quicksupport.summary")}
            </Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.support_id")}: </Typography><br/>
            <Typography>{data.systemId ?? "Unbekannt"}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.system_name")}: </Typography><br/>
            <Typography>{data.systemName ?? "Unbekannt"}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.current_version")}: </Typography><br/>
            <Typography>{data.version}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.host_id")}: </Typography><br/>
            <Typography>{data.hostId}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.type")}: </Typography><br/>
            <Typography>{t("quicksupport.types."+QuickSupportTypes.find(t => t.type == data.type)?.name)}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.support_content.name")}: </Typography><br/>
            <Typography>{data.name}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.support_content.mail")}: </Typography><br/>
            <Typography>{data.mail}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.support_content.phone")}: </Typography><br/>
            <Typography>{data.number}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.support_content.title")}: </Typography><br/>
            <Typography>{data.title}</Typography>
            <Typography color={"secondary"} variant={"caption"}>{t("quicksupport.support_content.text")}: </Typography><br/>
            <Typography>{data.text}</Typography>
            {!context.signInState && <ReCAPTCHA sitekey="6Len7BgnAAAAAHfUokbSZmD6zVGRWhD9ZSc0qVlV" ref={recaptchaRef}/>}
        </React.Fragment>
    );
}

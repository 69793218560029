import {
    AppBar,
    Box,
    Button, Container,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton, Link as MuiLink,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    TextField,
    Toolbar, useTheme
} from "@mui/material";
import {Close} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useApplicationContext} from "../../../ApplicationContext";
import {GetSupportCodeData} from "../../../apiClient/Api";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import QRCode from "react-qr-code";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

export interface SupportCodeComponentProps {
    supportId: string | undefined
    closeComponent: () => void
}


export const SupportCodeComponent = ({supportId, closeComponent}: SupportCodeComponentProps) => {
    const theme = useTheme();
    const context = useApplicationContext();
    const open = supportId != undefined
    const [generateSupportCode, setGenerateSupportCode] = useState(false);
    const [name, setName] = useState("")
    const [supportUrl, setSupportUrl] = useState("")
    const [supportCodeId, setSupportCodeId] = useState("")
    const [initialized, setInitialized] = useState(false)
    const [selectedSupportCode, setSelectedSupportCode] = useState<GetSupportCodeData | undefined>(undefined);
    const [supportCodeData, setSupportCodeData] = useState<GetSupportCodeData[]>([])
    const [showAlert, setShowAlert] = useState<boolean>(false);
    useEffect(() => {
        if (!initialized && supportId != undefined) {
            updateSupportData();
            setInitialized(true);
        }
    })

    const updateSupportData = () => {
        if(supportId != undefined){
            context.api.supportContract.supportContractGetSupportCodes(supportId).then(data => {
                if (data&& data.data != undefined) {
                    setSupportCodeData(data.data)
                }
            }).catch(context.apiError)
        }
    }


    const isValidUrl = (urlString: string) => {
        try {
            return Boolean(new URL(urlString));
        } catch (e) {
            return false;
        }
    }

    const reset = () => {
        setGenerateSupportCode(false)
        setSupportUrl("")
        setSupportCodeId("")
        setName("")
        setInitialized(false)
        setSelectedSupportCode(undefined)
    }

    function handleClose() {
        reset();
        setInitialized(false)
        closeComponent()
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
        setSelectedSupportCode(undefined)
    }

    const handleDelete = () => {

        if(selectedSupportCode){
            context.api.supportCode.supportCodeDeleteSupportCode(selectedSupportCode?.id!).catch(context.apiError).then(data => {
                if(data){
                    setShowAlert(false);
                    setSelectedSupportCode(undefined)
                    updateSupportData();
                }
            });
        }
    }

    const handleSave = () => {

        if (!isValidUrl(supportUrl)) {
            return;
        }
        context.api.supportCode.supportCodeAddSupportCode({
            supportId: supportId!,
            name: name,
            redirectUrl: supportUrl
        }).catch(context.apiError).then(() => {
            reset();
            updateSupportData();
        })
    }

    return <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar sx={{position: 'relative'}}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <Close/>
                </IconButton>
                <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                    Support codes bearbeiten
                </Typography>


            </Toolbar>
        </AppBar>
        {generateSupportCode ? <Box padding={4}>
                <Typography variant={"h5"}>Support Code generieren</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Support ID"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={supportCodeId != "" ? supportCodeId : "Automatisch"}
                    disabled={true}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name / Titel"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={event => setName(event.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Support URL"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={supportUrl}
                    onChange={event => setSupportUrl(event.target.value)}
                />

                <Button color={"error"} onClick={reset}>Abbrechen</Button>
                <Button color={"success"} onClick={handleSave}> Speichern</Button>

            </Box>
            : <>

                <Grid container spacing={2} height={"100%"}>
                    <Grid item md={selectedSupportCode !== undefined && !showAlert ? 8 : 12}>
                        <Box padding={2}>
                            <Button onClick={() => setGenerateSupportCode(true)}>Support Code generieren</Button>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Support ID</TableCell>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {supportCodeData.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                onClick={() => {
                                                    setSelectedSupportCode(row)
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="right">{row.name}</TableCell>
                                                <TableCell align="right">               <IconButton
                                                    edge="start"
                                                    color="primary"
                                                    onClick={() => {
                                                        setSelectedSupportCode(row)
                                                        setShowAlert(true)
                                                    }}
                                                    aria-label="close"
                                                >  <DeleteIcon/>

                                                </IconButton></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Grid>
                    {selectedSupportCode !== undefined && !showAlert &&
                        <Grid item md={4} height={"100%"}
                              style={{background: theme.palette.background.paper, borderLeft: "1px solid #CCCCCC"}}>
                            <Box paddingTop={4} alignItems={"center"} justifyContent={"center"} display={"flex"}
                                 flexDirection={"column"}>
                                <Typography variant={"h6"} color={"primary"} fontWeight={"bold"}>Support QR Code</Typography>
                                {selectedSupportCode.customUrl !== undefined &&
                                    <QRCode  value={selectedSupportCode?.customUrl!} bgColor={"#00000000"} fgColor={theme.palette.text.primary} />}
                                <MuiLink color={"secondary"} href={selectedSupportCode.customUrl!} target={"_blank"}> <Typography
                                    textAlign={"center"}>{selectedSupportCode.customUrl}</Typography></MuiLink>
                            </Box>
                            <Box paddingTop={2}>


                                <Typography variant={"h6"} color={"primary"} fontWeight={"bold"}>Daten</Typography>
                                <TableContainer>
                                    <Table size="small" aria-label="a dense table">
                                        <TableBody>
                                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell align="left" style={{fontWeight: "bold"}}>Support ID</TableCell>
                                                <TableCell align="right">{selectedSupportCode.id}</TableCell>
                                            </TableRow>
                                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell align="left" style={{fontWeight: "bold"}}>Name</TableCell>
                                                <TableCell align="right">{selectedSupportCode.name}</TableCell>
                                            </TableRow>
                                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell align="left" style={{fontWeight: "bold"}}>Support URL</TableCell>
                                                <TableCell align="right">{selectedSupportCode.customUrl}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>


                        </Grid>
                    }
                </Grid>

            </>}

        <Dialog
            open={showAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Möchten Sie die Support ID wirklich löschen?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Die Support ID wird als gelöscht markiert. Kunden können nicht mehr auf die Daten zugreifen.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAlert} autoFocus>Abbrechen</Button>
                <Button onClick={handleDelete} color={"error"}>
                    Löschen
                </Button>
            </DialogActions>
        </Dialog>

    </Dialog>
}

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AddressForm from './QuickSupport/AddressForm';
import SupportContentForm from './QuickSupport/SupportContentForm';
import Review from './QuickSupport/Review';
import {Copyright} from "Components/Copyright";
import {useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {GetQuickSupportData} from "../apiClient/Api";
import {useApplicationContext} from "../ApplicationContext";
import {Buffer} from 'buffer';
import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from 'react-i18next'
import {QuickSupportTypes} from "./QuickSupport/QuickSupportTypes";
import LanguageButton from "../Components/LanguageButton";




export type ServiceCodeRouteParams = {
    si: string
    hw: string
    sw: string;
    st: string
    ts: string
    vf: string
    e: string
    f: string
    hi: string
    name: string

}


const defaultRouteParams : ServiceCodeRouteParams = {
    e: "", f: "", hw: "", si: "", st: "", sw: "", ts: "", vf: "", name: "", hi: ""

}

const decodeBase64 = (data: string) => {
    return Buffer.from(data, 'base64').toString('ascii');
}

const encodeBase64 = (data: string) => {
    return Buffer.from(data).toString('base64');
}

export     function ErrorEmail(mail: string)
{
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

export function  ErrorPhone(phone : string){
    if(phone.length == 0)
        return false;
    return !/^([+][0-9]{1,3}[ .-])?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/.test(phone)
}


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export const QuickSupport = () => {
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [supportTicketId, setSupportTicketId] = React.useState("");

    const [searchParams, setSearchParams] = useSearchParams()
    const context = useApplicationContext();
    const [systemData, setSystemData] = useState<GetQuickSupportData>();
    const [routeParams, setRouteParams] = useState<ServiceCodeRouteParams>(defaultRouteParams);
    const [name, setName] = useState<string>("")
    const [number, setNumer] = useState<string>("")
    const [mail, setMail] = useState<string>("")
    const [title, setTitle] = useState<string>("")
    const [text, setText] = useState<string>("");
    const [supportType, setSupportType] = useState<number>(0);
    const [initialized, setInitialized] = useState<number>(-1)
    const [supportId, setSupportId] = useState("");
    const [invalidSupportIdFormat, setInvalidSupportIdFormat] = useState("");
    const [supportIdLength, setSupportIdLength] = useState("");
    const recaptchaRef = React.useRef<ReCAPTCHA>(null);
    const [supportIdTryCount, setSupportIdTryCount] = useState(0);
    const [hostID, setHostId] = useState("")
    const [supportIdState, setSupportIdState] = useState(0)
    const [selectedDSGVO, setSelectedDSGVO] = useState(false);
    const [selectedPaySelf, _setSelectedPaySelf] = useState<null | boolean>(null);
    const [selectedPayOwner, _setSelectedPayOwner] = useState<null | boolean>(null);
    const [selectedIsGuarantee, _setSelectedIsGuarantee] = useState<null | boolean>(null);


    var setSelectedPaySelf = useCallback((value: boolean) => {
        _setSelectedPaySelf(value);
        _setSelectedPayOwner(!value)
        _setSelectedIsGuarantee(!value)
    }, [selectedPayOwner, selectedPaySelf, selectedIsGuarantee])


    var setSelectedPayOwner = useCallback((value: boolean) => {
        _setSelectedPaySelf(!value);
        _setSelectedPayOwner(value)
        _setSelectedIsGuarantee(!value)
    }, [selectedPayOwner, selectedPaySelf, selectedIsGuarantee])


    var setSelectedIsGuarantee = useCallback((value: boolean) => {
        _setSelectedPaySelf(!value);
        _setSelectedPayOwner(!value)
        _setSelectedIsGuarantee(value)
    }, [selectedPayOwner, selectedPaySelf, selectedIsGuarantee])


    const [currentVersion, setCurrentVersion] = useState("")

    const steps = [t("quicksupport.system_data"), t('quicksupport.support_request'), t('quicksupport.send')];

    const _setQuickSupportId = useCallback((id: string | null | undefined) => {
        if(id === undefined || id === null){
            setSupportId("")
            return;
        }
        else{
            if(id.length >= 6 && id.at(5) != "-"){
               id = id.substring(0,5) + "-" + id.substring(5)
            }
            if(id.length <= 11){

                setSupportId(id.toUpperCase());
            }
        }


    }, [supportId, supportIdLength])


    const nextStepPossible = () => {
        if (activeStep === 0) {
            if (supportIdState === -1) {
                return false;
            }
            if (supportIdState >= 1) {
                if (currentVersion.length < 5) {
                    return false;
                }
            }
            if (supportIdState == 2) {
                if (hostID.length < 5)
                    return false;
            }
        }
        if (activeStep == 1) {

            if(selectedDSGVO && QuickSupportTypes.find(f => f.type == supportType) &&  (!QuickSupportTypes.find(f => f.type == supportType)?.displayPayment  || selectedIsGuarantee || selectedPaySelf || selectedPayOwner))
            {
                if (name.length > 3 && !ErrorEmail(mail) && !ErrorPhone(number) && title.length > 3 && text.length > 5) {
                    return true;
                }
            }

            return false;
        }
        return true;
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <AddressForm supportIdState={supportIdState} currentVersion={currentVersion} setCurrentVersion={setCurrentVersion} hostId={hostID} setHostId={setHostId} invalidSupportIdFormat={invalidSupportIdFormat} quickSupport={systemData} routeParams={routeParams} quickSupportId={supportId} setQuickSupportId={_setQuickSupportId}/>;
            case 1:
                return <SupportContentForm supportIdState={supportIdState} supportType={supportType} setSupportType={setSupportType} name={name}
                                           number={number} text={text} title={title} mail={mail} setMail={setMail}
                                           setName={setName} setNumber={setNumer} setText={setText}
                                           selectedIsGuarantee={selectedIsGuarantee} setSelectedIsGuarantee={setSelectedIsGuarantee}
                                           selectedPayOwner={selectedPayOwner} setSelectedPayOwner={setSelectedPayOwner}
                                           selectedPaySelf={selectedPaySelf} setSelectedPaySelf={setSelectedPaySelf}
                                           selectedDSGVO={selectedDSGVO} setSelectedDSGVO={setSelectedDSGVO}
                                           setTitle={setTitle}/>;
            case 2:
                return <Review ok={true} recaptchaRef={recaptchaRef} data={{
                    systemId: systemData?.id,
                    systemName: systemData?.name,
                    type: supportType,
                    hostId: hostID,
                    version: currentVersion,
                    name,
                    number,
                    mail,
                    title,
                    text
                }}/>;
            default:
                throw new Error('Unknown step');
        }
    }




    const handleNext = () => {

        if(!nextStepPossible())
            return;


        if (activeStep === steps.length - 1) {
            var reCaptcha = recaptchaRef.current?.getValue() ?? "";

            context.api.supportCode.supportCodeCreateQuickSupport({
                SystemId: supportId,
                CurrentVersion: currentVersion,
                HostId: hostID ?? "UNBEKANNT",
                SystemName: systemData?.name,
                SupportContractId: systemData?.contractContractId ?? "",
                RequestType: supportType,
                Name : name,
                Phone: number.length > 0 ? number : "",
                EMail: mail,
                Title : title,
                Text: text,
                SelectedDSGVO: selectedDSGVO ?? false,
                SelectedPaySelf: selectedPaySelf ?? false,
                SelectedPayOwner: selectedPayOwner ?? false,
                SelectedIsGuarantee: selectedIsGuarantee ?? false,
                ReCaptchaToken: reCaptcha,
                TestMessage: false
            }).then((data) => {
                if(data.data != null)
                    setSupportTicketId(data.data)
                setActiveStep(activeStep + 1);
            }).catch(context.apiError)
            return;
        }
        setActiveStep(activeStep + 1);

    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const regex = /^([A-Z0-9]{5})-([A-Z0-9]{5})$/;


    useEffect(() => {
        var q = searchParams.get("q") ?? "";
        let routeParamsLoc: ServiceCodeRouteParams | undefined = undefined;
        if (q?.length > 0) {
            routeParamsLoc = {...defaultRouteParams, ...JSON.parse(decodeBase64(q))}
        } else {
            var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            var siTemp = searchParams.get("si") ?? "";
            if(base64regex.test(siTemp)){
                siTemp = decodeBase64(siTemp);
            }
            routeParamsLoc = {
                e: searchParams.get("e") ?? "",
                f: searchParams.get("f") ?? "",
                hw: searchParams.get("hw") ?? "",
                si: siTemp,
                name: searchParams.get("name") ?? "",
                st: searchParams.get("st") ?? "",
                ts: searchParams.get("ts") ?? "",
                vf: searchParams.get("vf") ?? "",
                sw: searchParams.get("sw") ?? "",
                hi: searchParams.get("hi") ?? "",
            }
        }
        setRouteParams(routeParamsLoc!)

        let si = routeParamsLoc?.si ?? "";
        setSupportId(si)

        // Build host id ...
        var id = routeParamsLoc?.hw ?? "";
        var name = routeParamsLoc?.name ?? "";
        if(id.length > 0 && name.length > 0){
            id += " / ";
        }
        id += name;
        setHostId(name)
        setCurrentVersion(routeParamsLoc?.sw ?? "")

        if (context.userData) {
            if (context.userData.name)
                setName(context.userData.name)
            if (context.userData.eMail)
                setMail(context.userData.eMail)
        }

    }, [searchParams, context.userData])

    useEffect(() => {
            if(supportIdTryCount > 5){
                setInvalidSupportIdFormat(t('quicksupport.errors.to_many_tries'))
                return;
            }
            if(supportId.length == 0){
                setInvalidSupportIdFormat("");
                setTitle("");
                setText("");
                setSupportIdState(0)
            }
            else if(supportId.length < 11){
                setInvalidSupportIdFormat(t('quicksupport.errors.exact_11'))
                setSupportIdState(-1);
            }
            else if (supportId.length === 11 ) {
                setInvalidSupportIdFormat("")
                if(regex.test(supportId)){
                    setSupportIdTryCount(count => count+1);
                    var encoded = encodeBase64(supportId);
                    context.api.supportCode.supportCodeQuickSupport({data: encoded}).then(data => {
                        setSystemData(data.data)
                        if (!data.data.isValid) {
                            setSupportIdState(2);
                            setTitle(t('quicksupport.texts.default_register_title'))
                            setText(t('quicksupport.texts.default_register_text', {hwid: routeParams.hw}))
                        }
                        else{
                            setSupportIdState(1);
                            setTitle("")
                            setText("")
                        }
                    })
                }
                else{
                    setSupportIdState(-1);
                    setInvalidSupportIdFormat(t('quicksupport.errors.wrong_format'))
                }

                // Send query to server to get system data...
            } else {
                setTitle(t('quicksupport.texts.default_register_title'))
                setText(t('quicksupport.texts.default_register_text', {hwid: routeParams.hw}))
            }


    }, [supportId])


    return (

        <>
            <Box sx={{
                position: "fixed",
                top:0,
                left:0,
                zIndex: -5,
                width: "100vw",
                height: "100vh",
                backgroundImage: 'url(/login_background.jpeg)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }} />

            {!context.signInState && <AppBar
                position="absolute"
                color="primary"
                elevation={2}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>



                    <Typography variant="h6" color="inherit"  noWrap sx={{flexGrow: 1}}>
                        Reichert GmbH
                    </Typography>
                    <LanguageButton />
                </Toolbar>

            </AppBar>}

            <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>

                    <Typography component="h1" variant="h4" align="center">
                        {t("quicksupport.title")}
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                {t("quicksupport.finalized.title")}
                            </Typography>
                            <Typography variant="subtitle1">
                                {t("quicksupport.finalized.message", {supportTicketId})}
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {getStepContent(activeStep)}
                            {!nextStepPossible() && <Typography color={"error"} fontWeight={"bold"} variant={"caption"}>Bitte füllen Sie alle Felder aus</Typography>}
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                                        {t("quicksupport.previous")}
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    disabled={!nextStepPossible()}
                                    sx={{mt: 3, ml: 1}}
                                >
                                    {activeStep === steps.length - 1 ? t('quicksupport.send') : t('quicksupport.next')}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Paper>
                {!context.signInState && <Copyright/>}
            </Container>
        </>
    );
}

import React, {useState, DragEvent, useRef, useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import {alpha} from "@mui/material";

interface FileUploadProps {
    onFileChange: (file: File | null) => void;
}

interface StyledDivProps {
    isDraggingOver: boolean;
}

const DropZone = styled('div')<StyledDivProps>(({ theme, isDraggingOver }) => ({
    border: `3px dashed ${theme.palette.text.secondary}`,
    borderRadius: '20px',
    padding: theme.spacing(3),
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: isDraggingOver ? theme.palette.action.hover : theme.palette.background.paper,
    color: theme.palette.text.secondary,
    boxShadow: `inset 0 0 10px ${alpha(theme.palette.text.primary, 0.5)}`, // Semi-transparent darker shadow
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

const HiddenInput = styled('input')({
    display: 'none',
});

const FileUpload: React.FC<FileUploadProps> = ({ onFileChange }) => {
    const [file, setFile] = useState<File | null>(null);
    const [isDraggingOver, setDraggingOver] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);


    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation()

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setFile(event.dataTransfer.files[0]);
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation()
        setDraggingOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation()

        setDraggingOver(false);
    };



    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        onFileChange(file)
    }, [file]);

    const triggerFileInput = () => {
        fileInputRef.current?.click();
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Hier können Sie die CoreDump Datei zur Auswertung hochladen.
            </Typography>
            <DropZone
                isDraggingOver={isDraggingOver}
                onClick={triggerFileInput}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
            >
                <Typography>Ziehen Sie die Datei hierher oder klicken Sie, um sie auszuwählen.</Typography>
                {file && <Typography variant={"caption"} sx={{fontWeight: "bold"}}>Datei: {file.name}</Typography>}
            </DropZone>
            <HiddenInput
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
            />

        </div>
    );
};

export default FileUpload;

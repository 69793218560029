import React, {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import {GdbServicePing} from "../../../apiClient/Api";
import {useApplicationContext} from "../../../ApplicationContext";
import Paper from "@mui/material/Paper";
import {Box} from "@mui/material";

type Props = {
    serviceFoundCallback?: (found: boolean) => void
}


const ServicesAccordion: React.FC<Props> = ({serviceFoundCallback}) => {

    const {api} = useApplicationContext()
    const [services, setServices] = useState<GdbServicePing[]>([])

    useEffect(() => {
        api.gdbService.gdbServiceServices().then(data => {
            if(data && data.data){
                setServices(data.data)
            }
        })
    }, []);

    useEffect(() => {
        if(serviceFoundCallback){
            serviceFoundCallback(services.length > 0)
        }
    }, [services]);


    if (services.length === 0) {
        return     <Paper style={{ display: 'flex', alignItems: 'center', backgroundColor: 'pink', color: 'red', padding: '20px', width: "80%" }}>
            <Box style={{ flex: '1 1 15%', display: 'flex', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} size="4x" />
            </Box>
            <Box style={{ flexGrow: 1 }}>
                <Typography variant="h3" textAlign={"center"} >Kein GDB Service verfügbar</Typography>
                <Typography textAlign="center" color="error" variant="subtitle1">Es ist leider kein GDB Service verfügbar. Um einen CoreDump hoch zu laden muss zuerst ein GDB Service verbunden werden.</Typography>
            </Box>
        </Paper>

    }

    return (
        <div>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Verfügbare GDB Services</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {services.map((service, index) => (
                        <div key={index}>
                            <Typography>Name: {service.serviceName}</Typography>
                            <Typography>Adresse: {service.serverAddress}</Typography>
                            <Typography>Build Types:</Typography>
                            <ul>
                                {service.buildType?.map((type, typeIndex) => (
                                    <li key={typeIndex}>{type}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default ServicesAccordion;

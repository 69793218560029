import {DataGrid, GridActionsCellItem, GridRowParams} from "@mui/x-data-grid";
import React, {useCallback, useEffect, useState} from "react";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Input, List, ListItem,
    Modal, Paper, Stack, styled,
    Typography
} from "@mui/material";
import {Edit, OpenInFull, Workspaces} from "@mui/icons-material";
import {VersionItem} from "../Components/VersionItem";
import {useDocumentTitle} from "../Hooks/useDocumentTitle";
import {useApplicationContext} from "../ApplicationContext";
import {GetVersionData, GetVersionDataCustomer} from "../apiClient/Api";
import {MuiMarkdown} from "mui-markdown";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

type RouteParams = {
    link: string
}
export const Versions = () => {
    useDocumentTitle("base.pages.versions")
    const { t } = useTranslation();
    const [data, setData] = useState<(GetVersionData | GetVersionDataCustomer)[]>([])
    const [currentVersion, setCurrentVersionData] = useState<GetVersionData | GetVersionDataCustomer | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0)
    const context = useApplicationContext();
    const params = useParams<RouteParams>()
    var controller = context.api.version;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSave = async () => {
        setOpen(false)
        if (currentVersion != null) {
            var value = await controller.versionUpdateVersion(currentVersion)
        }
    };

    const reloadCallback = useCallback(async () => {
        await fetchData()
    }, [data])

    async function fetchData() {

        if (!params.link)
            return;

        setIsFetching(true)

        var value = context.isInternal ? await controller.versionGetVersions(params.link) : await controller.versionGetCustomerVersions(params.link)
        if(value && value.data)
            setData(value.data)
        setIsFetching(false)

    }

    useEffect(() => {
        fetchData();
    }, [params.link])

    return (
        <>
            <Box sx={{width: '100%'}} padding={1}>
                <Stack spacing={2}>
                    {data.map(major => {
                        return (
                            <React.Fragment key={'versionElement_'+major.versionId}>

                                <Paper sx={{paddingLeft: 5, paddingTop: 2, paddingBottom: 2, paddingRight: 5}} key={"header_"+major.versionId}>
                                    <Typography variant={"h2"} color={"secondary"} textAlign={"center"}>{t('version.item.versionNumber', { version: major.major })}</Typography>
                                    {(major?.header?.length ?? 0) > 0 && <>
                                        <Typography sx={{fontSize: 22}} variant={"h3"} color={"secondary"}>{t('version.item.notes')}</Typography>
                                        <MuiMarkdown overrides={context.muiMarkdownOverrides}>{major.header}</MuiMarkdown>
                                    </>}

                                    <Button sx={{marginTop: 3, marginBottom: 3}} fullWidth size={"large"} variant={"outlined"}
                                            onClick={() => {
                                                const element = document.getElementById(`V${major.major}.${major.minor}.${major.patch}`);
                                                if (element) {
                                                    // Will scroll smoothly to the top of the next section
                                                    element.scrollIntoView({behavior: 'smooth'});
                                                }
                                            }}>
                                        {(major.files?.length ?? 0) > 0 ? t('version.item.crossVersionInformationAndFiles') : t('version.item.crossVersionInformation')}
                                    </Button>
                                </Paper>

                                {major?.childVersions?.map(minor => <>
                                    <Paper sx={{paddingLeft: 5, paddingTop: 2, paddingBottom: 2, paddingRight: 5}} key={"header_"+minor.versionId}>
                                        <Typography variant={"h3"} color={"primary"} textAlign={"center"}>{t('version.item.versionNumber', { version: `${major.major}.${minor.minor}` })}</Typography>
                                        {(minor?.header?.length ?? 0) > 0 && <>
                                            <Typography sx={{fontSize: 22}} variant={"h4"} color={"secondary"}>{t('version.item.notes')}</Typography>
                                            <MuiMarkdown overrides={context.muiMarkdownOverrides}>{minor.header}</MuiMarkdown>
                                        </>}

                                        <Button sx={{marginTop: 3, marginBottom: 3}} fullWidth size={"large"} variant={"outlined"}
                                                onClick={() => {
                                                    const element = document.getElementById(`V${minor.major}.${minor.minor}.${minor.patch}`);
                                                    if (element) {
                                                        // Will scroll smoothly to the top of the next section
                                                        element.scrollIntoView({behavior: 'smooth'});
                                                    }
                                                }}>
                                            {(minor.files?.length ?? 0) > 0 ? t('version.item.crossVersionInformationAndFiles') : t('version.item.crossVersionInformation')}
                                        </Button>
                                    </Paper>
                                    {minor?.childVersions?.map(patch => <>
                                        <VersionItem data={patch} reloadCallback={reloadCallback} key={patch.versionId}/>
                                    </>)}
                                    <VersionItem data={minor} reloadCallback={reloadCallback} key={minor.versionId}/>
                                </>)}

                                <VersionItem data={major} reloadCallback={reloadCallback} key={major.versionId}/>
                            </React.Fragment>
                        );
                    })}
                </Stack>
            </Box>
        </>
    );
}



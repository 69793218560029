import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Input,
    InputLabel, LinearProgress, MenuItem,
    Select, SelectChangeEvent, Typography
} from "@mui/material";
import {useApplicationContext} from "../ApplicationContext";
import {GetFileTypeData, GetHostSystemData} from "../apiClient/Api";
import {applyInitialState} from "@mui/x-data-grid/hooks/features/columns/gridColumnsUtils";
import {determineMimeType} from "../Hooks/CustomFileType";


export interface UploadVersionFileDialogProps {
    currentVersion: { major: number, minor: number, patch: number, productId: string } | undefined
    resetSelectedVersion: () => void
}

export const UploadVersionFileDialog = (props: UploadVersionFileDialogProps) => {

    const context = useApplicationContext();
    const [hostSystem, setHostSystem] = React.useState('');
    const [fileTypes, setFileTypes] = React.useState<GetFileTypeData[]>([]);
    const [hostSystems, setHostSystems] = React.useState<GetHostSystemData[]>();
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const [fileType, setFileType] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState<File | undefined>(undefined)
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false)
    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            context.api.fileType.fileTypeGetHostSystems().then(systems => setHostSystems(systems.data)).catch(context.apiError);
        }
    }, [initialized])


    const uploadFile = () => {

        if (props.currentVersion && selectedFile && fileType.length > 0) {
            setUploading(true)
            // @ts-ignore
            context.api.version.versionOnPostUpload(
                selectedFile
                , {
                    Major: props.currentVersion!.major,
                    Minor: props.currentVersion.minor,
                    Patch: props.currentVersion.patch,
                    ProductId: props.currentVersion.productId,
                    FileTypeId: fileType,
                    FileName : selectedFile.name,
                    ContentType: determineMimeType(selectedFile)
                }, {
                    onUploadProgress: progressEvent => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        }
                    },
                    //@ts-ignore
                    type: "application/octet-stream"
                }).then(error => {
                context.apiError(error)
                setSelectedFile(undefined);
                setFileType("");
                setUploading(false)

                props.resetSelectedVersion();
            }).catch(error => {
                context.apiError(error)
                setUploading(false)
            })

        } else {
            props.resetSelectedVersion();
        }
    }


    const handleChange = async (event: SelectChangeEvent) => {
        const _hostSystem = event.target.value as string;
        if (_hostSystem !== hostSystem) {
            setHostSystem(event.target.value as string);
            try {
                var fileTypes = (await context.api.fileType.fileTypeGetFileTypes()).data;
                setFileTypes(fileTypes.filter(f => f.hostSystem?.id == _hostSystem));
                setFileType("");
            } catch (e) {
                context.apiError(e);
            }
        }
    };


    const handleChangeFileType = (event: SelectChangeEvent) => {
        setFileType(event.target.value as string);
    };


    const handleChangeSelectedFil = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != null) {
            var file = event.target.files[0];
            setSelectedFile(file)
        }
    };


    const open = props.currentVersion != undefined;
    return <>
        {props.currentVersion != undefined &&

            <Dialog open={open}>
                <DialogTitle>
                    Datei Upload für
                    V{props.currentVersion.major}.{props.currentVersion.minor}.{props.currentVersion.patch}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={{marginTop: 15}}>
                        <InputLabel id="host-system-label">Host System</InputLabel>
                        <Select
                            labelId="host-system-label"
                            id="host-system"
                            value={hostSystem}
                            label="Host System"
                            onChange={handleChange}
                        >
                            {hostSystems?.map(fileType => <MenuItem value={fileType.id!}>{fileType.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth style={{marginTop: 15}}>
                        <InputLabel id="file-type-label">Datei Typ</InputLabel>
                        <Select
                            labelId="ile-type-label"
                            id="ile-type"
                            value={fileType}
                            label="Datei Typ"
                            onChange={handleChangeFileType}
                            disabled={hostSystem.length == 0}

                        >
                            {fileTypes.map(fileType => <MenuItem value={fileType.id!}>{fileType.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth style={{marginTop: 15}}>
                        <Button
                            variant="contained"
                            component="label"
                            disabled={uploading}
                        >
                            Datei auswählen
                            <Input type="file" style={{"display": "none"}} onChange={handleChangeSelectedFil}></Input>
                        </Button>
                        {selectedFile && <Typography>Ausgewählt: {selectedFile?.name}</Typography>}
                    </FormControl>

                    {uploading && <LinearProgress variant="determinate" value={uploadProgress}/>}
                </DialogContent>
                <DialogActions>
                    <Button color={"error"} onClick={props.resetSelectedVersion}>Abbrechen</Button>

                    <Button color={"success"} onClick={uploadFile} disabled={uploading}>Datei hochladen</Button>
                </DialogActions>
            </Dialog>

        }
    </>
}

import React, {useEffect, useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItemText, ListItem} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface ConsoleStreamOutputFrameData {
    level?: number;
    addr?: string;
    func?: string;
    args?: string | object;
    arch?: string;
    source?: string;
    file?: string;
    fullname?: string;
    line?: number;
    lines: string[];
}

function parseCustomString(input: string): any[] | string {
    try {
        // Step 1: Mark the start and end of 'value' strings
        let tempString = input;
        let insideValue = false;
        let newValueString = '';

        for (let i = 0; i < tempString.length; i++) {
            if (tempString[i] === '"' && tempString[i-1] !== '\\') {
                insideValue = !insideValue;
            }

            if (insideValue || tempString[i] === '"') {
                newValueString += tempString[i];
            } else {
                // Replace '=' with ':' outside of 'value' strings
                newValueString += tempString[i] === '=' ? ':' : tempString[i];
            }
        }

        // Step 2: Add double quotes around keys
        newValueString = newValueString.replace(/(\w+)(:)/g, '"$1"$2');

        // Step 3: Parse as JSON
        return JSON.parse(newValueString);
    } catch (error) {
        console.error("Parsing error:", error);
        return input;
    }
}


type Param =  {
    name : string;
    value: string;
}



const ConsoleStreamOutputFrame = ({initialFrame, frameIndex}: { initialFrame: ConsoleStreamOutputFrameData, frameIndex: number }) => {
    const [frame, setFrame] = useState<ConsoleStreamOutputFrameData>(initialFrame);
    const [hasProperties, setHasProperties] = useState(false);
    const [argsAsObject, setArgsAsObject] = useState(false)
    const [properties, setProperties] = useState<any>()
    useEffect(() => {
        console.log(initialFrame.args)
        var currentFrame = initialFrame;

        setHasProperties(!!Object.entries(initialFrame).filter(([key, value]) => key !== 'lines' && value).length);
        setProperties({func: initialFrame.func, file: initialFrame.file, line: initialFrame.line})
        currentFrame.args = currentFrame.args !== undefined && typeof currentFrame.args == "string" ?  parseCustomString(currentFrame.args) : currentFrame.args;
        setArgsAsObject(typeof  currentFrame.args == "object");
        setFrame(currentFrame);
    }, [initialFrame]);



    return (
        <React.Fragment>
            {frame.lines != null && frame.lines.length && !hasProperties ? <>
                <ul>
                    {frame.lines.map((line, index) => <li key={index}>{line}</li>)}
                </ul>
            </> : null}
            {hasProperties && (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>{properties.func} ({properties.line})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {frame.lines && frame.lines.length > 0 && <div>
                            <hr/>
                            {frame.lines.length > 1 ?
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant={"subtitle2"}>Info:</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {frame.lines && frame.lines.map((line, index) =>
                                            <React.Fragment key={index}>
                                                <p>{line}</p>
                                                {index < frame.lines.length - 1 && <hr/>}
                                            </React.Fragment>
                                        )}
                                    </AccordionDetails>
                                </Accordion> : <> <Typography variant={"subtitle2"}>Info:</Typography>
                                    <Typography>{frame.lines[0]}</Typography>
                                </>}
                        </div>}
                        <List dense={true}>
                            {Object.entries(frame).filter(([key, value]) => key !== 'lines' && value).map(([key, value]) => {
                                if (key === 'args' && Array.isArray(value)) {
                                    // If value is an object (presumably of type Param), create a sublist for it
                                    return (
                                        <ListItem key={key} style={{ display: 'block' }}>
                                            <ListItemText primary={key.charAt(0).toUpperCase() + key.slice(1)} />
                                            <List dense={true}>
                                                {value.map((item : Param, index) =>
                                                    <ListItem key={index}>
                                                        <ListItemText primary={item.name.charAt(0).toUpperCase() + item.name.slice(1)} secondary={item.value} />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </ListItem>
                                    );
                                }

                                return (
                                    <ListItem key={key}>
                                        <ListItemText
                                            primary={key.charAt(0).toUpperCase() + key.slice(1)}
                                            secondary={typeof value !== "object" ? value : JSON.stringify(value)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
            )}
        </React.Fragment>
    );
}

export default ConsoleStreamOutputFrame;

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {GetQuickSupportData} from "../../apiClient/Api";
import {ServiceCodeRouteParams} from "../QuickSupport";
import {useApplicationContext} from "../../ApplicationContext";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material";

interface IAddressForm {

    quickSupport?: GetQuickSupportData

    hostId: string
    setHostId: (data: string) => void;
    currentVersion: string
    setCurrentVersion: (data: string) => void;
    quickSupportId: string
    setQuickSupportId: (data: string) => void;
    supportIdState: number
    invalidSupportIdFormat: string;
    routeParams: ServiceCodeRouteParams
}

export default function AddressForm(props: IAddressForm) {
    const context = useApplicationContext();
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("quicksupport.system_data")}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={t("quicksupport.support_id")}
                        fullWidth
                        autoFocus
                        value={props.quickSupportId}
                        helperText={t("quicksupport.support_id_unknown")}
                        onChange={e => props.setQuickSupportId(e.target.value)}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={t('quicksupport.current_version')}
                        fullWidth
                        value={props.currentVersion}
                        required={props.supportIdState> 0}
                        helperText={t('quicksupport.current_version_format')}
                        error={props.supportIdState> 0 && props.currentVersion.length < 3}
                        onChange={e => props.setCurrentVersion(e.target.value)}
                        variant="standard"
                    />
                </Grid>
                {props.quickSupportId.length > 0 && <>{props.invalidSupportIdFormat.length > 0 ?
                    <Grid item xs={12}><Typography color={"error"} variant={"body2"} textAlign={"center"}>{props.invalidSupportIdFormat}
                    </Typography> </Grid>
                    :
                    props.quickSupport?.isValid ? <Grid item xs={12}>
                            <Typography color={theme.palette.success.main} variant="body2" align="center">
                                {t("quicksupport.errors.valid_id")}
                            </Typography>
                        </Grid> :
                        <Grid item xs={12}>
                            <Typography color="error" variant="body2" align="center">
                                {t("quicksupport.errors.unknown_system")}
                            </Typography>
                            <Typography variant="body2" align="center" color="grey">
                                {t("quicksupport.add_contact_data")}
                            </Typography>
                        </Grid>
                }</>}



                {props.quickSupport?.isValid && context.signInState && (props.quickSupport.contractContractId?.length ?? 0) == 0 &&
                    <Grid item xs={12}>
                        <Typography color={"error"} variant={"body2"} textAlign={"center"}>{t("quicksupport.errors.not_assigned")}<br/>
                        </Typography>
                    </Grid>}
                <Grid item xs={12} sm={12}>
                    <TextField
                        label={t("quicksupport.host_id")}
                        fullWidth
                        disabled={props.supportIdState == 1}
                        required={props.supportIdState == 2}
                        error={props.supportIdState == 2 && props.hostId.length < 5}
                        helperText={t("quicksupport.host_id_helper")}
                        value={props.hostId}
                        onChange={e => props.setHostId(e.target.value)}

                        variant="standard"
                    />
                </Grid>
                {context.signInState &&
                    <React.Fragment>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label={t("quicksupport.system_name")}
                                fullWidth
                                value={props.quickSupport?.name ?? "-"}
                                variant="standard"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t("quicksupport.contract_id")}
                                fullWidth
                                value={props.quickSupport?.contractContractId ?? "-"}
                                disabled
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t("quicksupport.lbl_title")}
                                fullWidth
                                value={(props.quickSupport?.contractTitle?.length == 0) ? "-" : props.quickSupport?.contractTitle ?? "-"}
                                disabled
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label={t("quicksupport.runtime")}
                                fullWidth
                                disabled
                                variant="standard"
                                value={props.quickSupport?.contractInfinite ? "Automatische erneuerung" : props.quickSupport?.contractExpire ?? "-"}
                            />
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </React.Fragment>
    );
}

import {useDocumentTitle} from "../Hooks/useDocumentTitle";
import {useApplicationContext} from "../ApplicationContext";
import React, {useCallback, useEffect, useState} from "react";
import {GetUserData} from "../apiClient/Api";
import {DataGrid, GridActionsCellItem, GridColDef, GridRowParams, GridToolbarQuickFilter, GridValueFormatterParams} from "@mui/x-data-grid";
import {format, parseISO} from "date-fns";
import {
    CheckCircle,
    Edit,
    UploadFile,
    Lock,
    LockOpen,
    CreateNewFolder,
    PersonAdd,
    MarkEmailRead,
    MarkEmailUnread, InfoRounded
} from "@mui/icons-material";
import {Axios, AxiosError} from "axios";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {Button, TableContainer} from "@mui/material";
import {AddUserComponent} from "../Components/Admin/User/AddUserComponent";
import Typography from "@mui/material/Typography";
import {applyInitialState} from "@mui/x-data-grid/hooks/features/columns/gridColumnsUtils";
import {DeleteUserDialog} from "../Components/Admin/User/DeleteUserDialog";
import Paper from "@mui/material/Paper";
import {AdvancedInfoDialog} from "../Components/Admin/User/AdvancedInfoDialog";
import Grid from "@mui/material/Grid";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEnvelopeCircleCheck, faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons'

export const AdminUsers = () => {
    useDocumentTitle("base.pages.users")
    const context = useApplicationContext();
    const [users, setUsers] = useState<GetUserData[]>([]);
    const [initialized, setInitialized] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [newUserOpen, setNewUserOpen] = useState(false);
    const [deleteUserID, setDeleteUserId] = useState<undefined | string>( undefined);
    const [advancedInfoUserId, setAdvancedInfoUserId] = useState<undefined | string>( undefined);

    const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
    const closeNewUserCallback = useCallback((created: boolean) => {
        setNewUserOpen(false);
        setSelectedId(undefined)
        if(created){
            updateUserData();
        }
    }, [newUserOpen])

    const advancedInfoClosed = useCallback(() =>{
        setAdvancedInfoUserId(undefined);
    } , [advancedInfoUserId])

    const userDeletedCallback = useCallback((deleted: boolean) =>{
        setDeleteUserId(undefined);
        if(deleted){
            updateUserData();
        }
    } , [deleteUserID])

    const EditToolbar = () =>  <Grid container sx={{marginTop: 2,marginBottom: 1}}> <Grid md={8}> <Button  onClick={() => {
        setNewUserOpen(true)
    }} startIcon={<PersonAdd/>}>Neuen Nutzer anlegen</Button> </Grid><Grid md={4}><GridToolbarQuickFilter /></Grid> </Grid>

    const updateUserData = () => {
        setIsFetching(true)
        context.api.user.userListUsers().then(usersdata => {
            setUsers(usersdata.data);
           setIsFetching(false)
        }).catch(context.apiError)
    }


    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            updateUserData()
        }
    }, [initialized, users])

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => {
                return <Button
                    variant="text"
                    size="large"
                    color="primary"
                    target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${params.row.eMail}`}
                >
                    <Typography variant="button" style={{ fontSize: '0.69rem' }}>
                        {params.row.name || ''}
                    </Typography>
                </Button>
            }

        },
        {field: "eMail", headerName: "Mail", flex: 1},
        {
            field: "userGroup",
            type: "singleSelect",
            valueOptions: (params) => {
                return [{value: 0, label: "Super Admin"},
                    {value: 1, label: "Admin"},
                    {value: 2, label: "Entwickler"},
                    {value: 3, label: "Büro"},
                    {value: 4, label: "Kunde"}]
            },
            headerName: "Nutzergruppe",
            valueFormatter: (params: GridValueFormatterParams<number | undefined>) => {
                if (params.value != undefined) {
                    switch (params.value) {
                        case 0:
                            return "Super Admin";
                        case 1:
                            return "Admin";
                        case  2:
                            return "Entwickler";
                        case 3:
                            return "Büro";
                        case 4:
                            return "Kunde";
                    }
                }
                return "Unbekannt"
            },
            width: 130
        },
        {
            field: "lastLogin",
            width: 200,
            headerName: "Letzter Login",
            valueFormatter: (params: GridValueFormatterParams<string | undefined>) => {
                if (params.value == undefined) {
                    return "Nicht eingeloggt"
                }
                const date = parseISO(params.value.toString()) // not MM-DD-YY
                return format(date, "dd.MM.yyyy hh:mm")
            }

        },
        {
            field: 'actions',
            type: 'actions',
            width: 200,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label={"Nutzer Informationen"}  onClick={() => {
                    setAdvancedInfoUserId(params.row.id);
                }} icon={<InfoRounded/>}/>,
                <GridActionsCellItem label={"Nutzer bearbeiten"} onClick={() => {
                    setSelectedId(params.row.id);
                    setNewUserOpen(true);
                }} icon={<Edit/>}/>,
                <GridActionsCellItem label={!params.row.locked ? "Nutzer sperren" : "Nutzer freigeben"} onClick={() => {
                    context.api.user.userLockUser(params.row.id).then(async (data) => {
                        if (data.data) {
                            await updateUserData();
                        }
                    }).catch(context.apiError)
                }} icon={!params.row.locked ? <Lock/> : <LockOpen/>}/>,

                <GridActionsCellItem label={"Nutzer löschen"} disabled={(context.userData!.userGroup ?? 4) >= 2} onClick={() => {
                    setDeleteUserId(params.row.id);

                }} icon={<DeleteIcon/>}/>,
                <GridActionsCellItem label={params.row.lastLogin == undefined ? "Login Daten verschicken" : "Nutzer war bereits eingeloggt"} onClick={() => {
                    if(params.row.lastLogin == undefined){
                        context.api.user.userResendFirstLogin(params.row.id).then(u => {
                            if(u.data)
                              alert("Daten wurden an Nutzer versandt")
                        }).catch(context.apiError)
                    }

                }} icon={params.row.lastLogin == undefined ? params.row.sentWelcomeMail == undefined ? <FontAwesomeIcon icon={faEnvelope}/>  :<FontAwesomeIcon icon={faEnvelopeCircleCheck}/> : <FontAwesomeIcon icon={faEnvelopeOpen}/>}/>,
            ]
        }
    ]

    return <>
        <AddUserComponent open={newUserOpen} closeComponent={closeNewUserCallback} userId={selectedId} />
        <DeleteUserDialog userDeleted={userDeletedCallback} id={deleteUserID} />
        <AdvancedInfoDialog advancedInfoClosed={advancedInfoClosed} id={advancedInfoUserId} />
        <TableContainer component={Paper} style={{margin: 4}}>
            <DataGrid
                slots={{
                    toolbar: EditToolbar,
                }}
                initialState={{
                    filter: {
                        filterModel: {
                            items: [
                                {id: 1, field: "userGroup", operator: 'is', value: 4}
                            ]
                        }
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: 'lastLogin',
                                sort: 'desc',
                            },
                        ],
                    },
                }}

                rows={users}
                columns={columns}
                loading={isFetching}
                autoHeight={true}></DataGrid>
        </TableContainer>
    </>
}

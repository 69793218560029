import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {BaseLayout} from "./BaseLayout";
import {AdminDashboard} from "./Pages/AdminDashboard";
import {AdminVersions} from "./Pages/AdminVersions";
import {Versions} from "./Pages/Versions";
import {AdminHostSystems} from "./Pages/AdminHostSystem";
import {AdminFileTypes} from "./Pages/AdminFileType";
import {useApplicationContext} from "./ApplicationContext";
import {SignInSide} from "./Pages/SignIn";
import {AdminUsers} from "./Pages/AdminUsers";
import {AdminSupportContracts} from "./Pages/AdminSupportContracts";
import {QuickSupport} from "./Pages/QuickSupport";
import {NotFound} from "./Pages/NotFound";
import {ServiceCodeNew} from "./Pages/ServiceCodeNew";
import {AcceptTerms} from "./Pages/AcceptTerms";
import {AdminTerms} from "./Pages/AdminTerms";
import {ChangePassword} from "./Pages/ChangePassword";
import {EUserGroup} from "./apiClient/Api";
import {FilterTags} from "./Pages/FilterTags";
import {ServiceCode} from "./Pages/ServiceCode";
import {SignUp} from "./Pages/SignUp";
import {HostDown} from "./Pages/HostDown";
import {CoreDumpData} from "./Pages/Admin/CoreDumps/CoreDumpData";
import {CoreDumpsList} from "./Pages/Admin/CoreDumps/CoreDumpsList";
import CoreDumpUpload from "./Pages/Admin/CoreDumps/CoreDumpUpload";

function App() {
    const context = useApplicationContext();

    if(context.networkError){
        return <BrowserRouter>
            <Routes>
                <Route path="*" element={<HostDown/>}/>
            </Routes>
        </BrowserRouter>
    }


    return (
       <>
           {context.needChangePassword ? <ChangePassword /> : context.signInState && context.displayAcceptTerms ? <AcceptTerms/> : <>
                <BrowserRouter>

                    {context.signInState && context.userData !== undefined ? <>
                        <Routes>
                            <Route path="/" element={<BaseLayout/>}>
                                <Route path="" element={<AdminDashboard/>}/>
                                <Route path={"quicksupport/:id"} element={<QuickSupport/>}/>
                                <Route path="versions/:link" element={<Versions/>}/>
                                <Route path="terms" element={<AdminTerms/>}/>
                                <Route path="quicksupport" element={<QuickSupport/>}/>
                                {context.userData!.userGroup! > EUserGroup.DEVELOPMENT &&   <Route path="support" element={<QuickSupport/>}/>}
                                {context.userData!.userGroup! <= EUserGroup.DEVELOPMENT && <Route path="support" element={<ServiceCode/>}/>}
                                {context.userData!.userGroup! <= EUserGroup.OFFICE  &&
                                    <Route path="admin">
                                        <Route path="supportcontracts" element={<AdminSupportContracts/>}/>
                                        <Route path="coredumps" element={<CoreDumpsList/>}/>
                                        <Route path="coredumps/upload" element={<CoreDumpUpload/>}/>
                                        <Route path="coredumps/:id" element={<CoreDumpData/>}/>
                                        <Route path="users" element={<AdminUsers/>}/>
                                        {/* Developer, Admin and SuperAdmin */}
                                        {context.userData!.userGroup! <= EUserGroup.DEVELOPMENT  &&
                                            <>
                                                <Route path="password_generator" element={<ServiceCodeNew/>}/>
                                                <Route path="versions/:link" element={<AdminVersions/>}/>
                                                {/* Only Admin and SuperAdmin */}
                                                {context.userData!.userGroup! <= EUserGroup.ADMIN &&
                                                    <>
                                                        <Route path="systems" element={<AdminHostSystems/>}/>
                                                        <Route path="filetypes" element={<AdminFileTypes/>}/>
                                                        <Route path="terms" element={<AdminTerms/>}/>
                                                        <Route path="filterTags" element={<FilterTags/>}/>
                                                    </>}
                                            </>}
                                    </Route>}
                                <Route path="*" element={<NotFound/>}/>
                            </Route>
                        </Routes>

                    </> : <>
                        <Routes>
                            <Route path="/support" element={<QuickSupport/>}/>
                            <Route path="/quicksupport" element={<QuickSupport/>}/>
                            <Route path="/signup" element={<SignUp/>}/>
                            <Route path="*" element={<SignInSide/>}/>
                        </Routes></>
                    }

                </BrowserRouter>
            </>}
        </>
    );
}

export default App;

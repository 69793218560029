import {Box, Button, Typography, useTheme, Link} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSignalStreamSlash, faUserRobotXmarks
} from '@fortawesome/pro-duotone-svg-icons';
import React from "react";
export const HostDown = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                bgcolor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                width: '100vw', // Make the width 1.5 times of the parent
            }}
        >
            <Typography fontSize={60} gutterBottom fontWeight={"bold"}>
                <FontAwesomeIcon icon={faSignalStreamSlash} style={{marginRight: 8}}/>
                <FontAwesomeIcon icon={faUserRobotXmarks} style={{marginLeft: 8}}/>
            </Typography>
            <Typography component="h1" variant="h2" gutterBottom color="secondary">
                {t('networkError.title')}
            </Typography>
            <Typography variant="subtitle1" color="text.light">
                {t('networkError.supportingText')}
            </Typography>
            <Box sx={{marginTop: "25px"}}>
                <Link target={"_blank"} href={"https://reichert-gmbh.com/impressum/"} color={"inherit"}>{t('base.imprint')}</Link> | <Link target={"_blank"} href={"https://www.reichert-gmbh.com/datenschutz/"} color={"inherit"}>{t('base.privacyPolicy')}</Link>
            </Box>
        </Box>
    );
};

import {useApplicationContext} from "../ApplicationContext";
import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Toolbar
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {EUserGroup, GetTermsData} from "../apiClient/Api";
import {Close} from "@mui/icons-material";
import {MuiMarkdown} from "mui-markdown";


export const AdminTerms = () => {
    const context = useApplicationContext();
    const [currentTerms, setCurrentTerms] = useState<GetTermsData | undefined>(undefined);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [createNewOpen, setCreateNewOpen] = useState<boolean>(false);
    const [editTile, setEditTitle] = useState<string>("");
    const [editContent, setEditContent] = useState<string>("")
    const userGroup = (context.userData?.userGroup ?? EUserGroup.CUSTOMER);
    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            context.setTitle(userGroup <= EUserGroup.ADMIN ? "Aktuelle Bedingungen" : "Admin - Bedingungen");
            context.api.terms.termsGetLatestTerms().then(data => {
                if (data.data) {
                    setCurrentTerms(data.data);
                }
            }).catch(context.apiError)
        }
    }, [initialized, currentTerms])
    const handleClose = () => {
        setEditTitle("")
        setEditContent("")
        setCreateNewOpen(false)
    }

    const openEditTerms = () => {
        setEditContent(currentTerms?.content ?? "")
        setEditTitle(currentTerms?.versionTitle ?? "")
        setCreateNewOpen(true)
    }


    const handleSave = () => {
        context.api.terms.termsReplaceTerms({
            versionTitle: editTile,
            content: editContent
        }).then(data => {
            if (data.data != undefined) {
                setCurrentTerms(data.data);
            }
        }).catch(context.apiError);
        handleClose();
    }

    return <>

        {userGroup <= EUserGroup.ADMIN &&
            <Button fullWidth size={"large"} onClick={openEditTerms}>Bedingungen ersetzen</Button>}
        <Box>
            <Paper style={{flex: 1, flexGrow: 1, textAlign: "left"}} sx={{padding: 5}}>
                {currentTerms != undefined && <>
                    <Typography variant={"h2"}>{currentTerms?.versionTitle}</Typography>
                    <MuiMarkdown overrides={context.muiMarkdownOverrides}>{currentTerms?.content!}</MuiMarkdown></>}
            </Paper>
        </Box>
        {userGroup <= EUserGroup.ADMIN &&
            <Dialog fullScreen open={createNewOpen}>
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Neue Bedingungen hinzufügen
                        </Typography>


                    </Toolbar>
                </AppBar>
                <DialogContent>

                    <Box marginTop={5} marginLeft={5} marginRight={5}>
                        <TextField
                            fullWidth
                            label="Titel"
                            margin={"normal"}
                            variant="outlined"
                            value={editTile}
                            onChange={(e) => setEditTitle(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Bedingungen"
                            multiline
                            rows={40}
                            margin={"normal"}
                            variant="outlined"
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions><Button fullWidth size={"large"} variant={"contained"} color={"error"}
                                       onClick={handleSave}>Bedingungen überschreiben</Button></DialogActions>
            </Dialog>
        }
    </>
}

import React, { useState } from 'react';
import { List, ListItem, Collapse, Box, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

/**
 * Render a hierarchical data list display component.
 * Data can be any object containing information which needs to be rendered.
 * Uses recursion to Display nested elements
 *
 * @param {object} options - The options for the data list display component:
 *   @param {any} options.data - The data to be displayed.
 *   @param {number} [options.depth=0] - The current depth of the data list (used for indentation).
 * @returns {React.Element} - The rendered data list display component.
 */
const DataListDisplay = ({ data, depth = 0 }: { data: any; depth?: number }) => {
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});

    const handleClick = (key: string) => {
        setOpen({ ...open, [key]: !open[key] });
    };

    const renderValue = (value: any) => {

        if(value == null)
            return <></>;

        if (typeof value === 'object') {
            return <></>; // Nichts anzeigen, wenn es ein Objekt oder Array ist
        }
        return <Typography variant="body1">{value.toString()}</Typography>;
    };

    const renderListItem = (key: string, value: any) => {
        const isNested = Array.isArray(value) || (typeof value === 'object' && value !== null);

        return (
            <React.Fragment key={key}>
                <ListItem sx={{ pl: depth * 4 }} >
                    <Box onClick={() => isNested && handleClick(key)} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Typography variant="subtitle2" sx={{ flex: 1 }}>{key}</Typography>
                        {!isNested && renderValue(value)}
                        {isNested && (open[key] ? <ExpandLess /> : <ExpandMore />)}
                    </Box>
                </ListItem>
                {isNested && (
                    <Collapse in={open[key]} timeout="auto" unmountOnExit>
                        <DataListDisplay data={value} depth={depth + 1} />
                    </Collapse>
                )}
            </React.Fragment>
        );
    };

    return (
        <List component="div" disablePadding>
            {Object.entries(data).map(([key, value]) => renderListItem(key, value))}
        </List>
    );
};

export default DataListDisplay;

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Api, ChangePasswordData, SignInUserData} from "./apiClient/Api";
import axios, {AxiosError} from "axios";
import {useSnackbar} from "notistack";
import {MarkdownToJSX} from "markdown-to-jsx";
import {getOverrides} from "mui-markdown";
import {useTheme} from "@mui/material";
import {mergeDateAndTime} from "@mui/x-date-pickers/internals/utils/date-utils";

export const ApplicationContext = React.createContext<ApplicationContextProps | null>(null);

interface ApplicationContextProps {
    setTitle: (title: string) => void;
    signInState: boolean;
    signIn: (username: string, password: string, remember: boolean) => void
    signOut: () => void;
    pageTitle: string;
    baseUrl: string;
    userData: SignInUserData | undefined;
    isInternal: boolean;
    api: Api<any>
    token: string | undefined;
    acceptedTerms: boolean
    apiError: (error: any) => void
    acceptTerms: () => void
    setAcceptedTerms: (terms: boolean) => void
    displayAcceptTerms: boolean;
    setDisplayAcceptTerms: (terms: boolean) => void;
    muiMarkdownOverrides: MarkdownToJSX.Overrides
    needChangePassword: boolean
    updatePassword: (data: ChangePasswordData) => void;
    networkError: boolean
}

export const ApplicationContextProvider = ({children}: any) => {
    const [networkError, setNetworkError] = useState(false)
    const [pageTitle, setPageTitle] = useState("")
    const [userData, setUserData] = useState<SignInUserData | undefined>(undefined)
    const [signInState, setSignInState] = useState(false);
    const [checkSignInState, setCheckSignInState] = useState(false);
    const [token, setToken] = useState<string | undefined>(undefined)
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
    const [displayAcceptTerms, setDisplayAcceptTerms] = useState<boolean>(false);

    const [needChangePassword, setNeedChangePassword] = useState<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    // @ts-ignore
    const baseUrl = ServerConfig.SERVER_URL;
    const api = useMemo(() => {
        let api = new Api<string>({
            baseURL: baseUrl,
            securityWorker: (_token) =>
                _token ? {
                    headers: {
                        Authorization: "Bearer " + _token as string,
                    },
                } : {}
        });

        api.instance.interceptors.response.use((response) =>response, (error) => {
            if(axios.isAxiosError(error)){
                var axiosError = error as AxiosError;
                if(axiosError.code === "ERR_NETWORK"){
                    setNetworkError(true)
                    return;
                }
                return Promise.reject(error)
            }
            return Promise.reject(error)
        });

        if (token != undefined)
            api.setSecurityData(token)
        return api;
    }, [baseUrl, token]);

    useEffect(() => {
        if(networkError){
            var interval = setInterval(() => {
                api.user.userAlive().then(u => {
                    if(u && u.data)
                        window.location.reload()
                })
                //window.location.reload();
               // setNetworkError(false)
            }, 5000);
            return () => {
                clearInterval(interval)
            }
        }
    }, [networkError])

    const updateUserData = (data: SignInUserData) => {
        if (localStorage.getItem("userData") !== null) {
            localStorage.setItem("userData", JSON.stringify(data));
        } else {
            sessionStorage.setItem("userData", JSON.stringify(data));
        }
        setUserData(data);
    }

    const updatePassword = (data: ChangePasswordData) => {
        api.user.userChangePassword(data).then(() => {
            // Update user data ...
            setNeedChangePassword(false)
            var currentData = userData;

            if (currentData != undefined) {
                currentData.needChangePassword = false;
                updateUserData(currentData)
            }
        }).catch(apiError);
    }

    const updateApi = useCallback((data: SignInUserData | undefined, remember: boolean) => {
        if (data != undefined) {
            if (remember) {
                localStorage.setItem("userData", JSON.stringify(data))
            } else {
                sessionStorage.setItem("userData", JSON.stringify(data))
            }
            setUserData(data);
            setNeedChangePassword(data.needChangePassword == null ? true : data.needChangePassword)
            setSignInState(true);

            if (data.token != undefined) {
                setToken(data.token);
            }
        }
    }, [token, api])


    useEffect(() => {
        if (!checkSignInState) {
            setCheckSignInState(true);


            // Check is user is signed in ...
            var data = sessionStorage.getItem("userData")
            if (data != undefined) {
                updateApi(JSON.parse(data), false)
            } else {
                data = localStorage.getItem("userData")
                if (data != undefined) {
                    updateApi(JSON.parse(data), true)
                }
            }
        }
        if (signInState) {
            api.user.userAcceptedTerms({}).then(data => {
                if (data && data.data)
                    setAcceptedTerms(data.data)
            })
        }
    }, [checkSignInState, signInState]);

    const acceptTerms = useCallback(() => {
        api.user.userAcceptTerms().then(data => {
            setAcceptedTerms(true)
            setDisplayAcceptTerms(false)
        }).catch(apiError)
    }, [acceptedTerms, displayAcceptTerms])

    const signOut = () => {
        setSignInState(false);
        setNeedChangePassword(false);
        setDisplayAcceptTerms(false);
        setAcceptedTerms(false);
        setToken(undefined)
        localStorage.removeItem("userData");
        sessionStorage.removeItem("userData");
    }

    const signIn = async (username: string, password: string, remember: boolean) => {

        const userController = api.user;
        try {
            const signInResponse = await userController.userSignIn({username, password});
            if (signInResponse.status == 200) {
                updateApi(signInResponse.data, remember)
            }

        } catch (e) {
            apiError(e)
        }
    }
    const setTitle = (title: string) => {
        setPageTitle(title)
        document.title = "Reichert | " + title;
    }


    const muiMarkdownOverrides: MarkdownToJSX.Overrides = {
        ...getOverrides({}),
        h1: {
            component: 'h6',
            props: {
                style: {fontSize: 20, margin: 0, fontWeight: 'bold', color: theme.palette.primary.main},
            },
        },
        h2: {
            component: 'p',
            props: {
                style: {fontSize: 19, margin: 0, fontWeight: '500'},
            },
        },
        h3: {
            component: 'p',
            props: {
                style: {fontSize: 18, margin: 0, fontWeight: '500', fontStyle: 'italic'},
            },
        },
        h4: {
            component: 'p',
            props: {
                style: {fontSize: 16, margin: 0, fontWeight: '500', fontStyle: 'italic'},
            },
        },
        h5: {
            component: 'p',
            props: {
                style: {fontSize: 18, margin: 0, fontStyle: 'italic'},
            },
        },
    };

    const apiError = (error: any) => {

        if (error != undefined) {
            /// Write all errors to console

            if (error instanceof AxiosError) {
                var data = error.response?.data;
                console.warn(data)
                if (data != undefined) {
                    // Display error in ui ...
                    if (data.message) {
                        enqueueSnackbar(data.message, {variant: "error"})
                    } else if (data.title) {
                        enqueueSnackbar(data.title, {variant: "error"})
                    }

                    if (data.title || data.message) {
                        console.error(`${data.title}\n${data.message ?? "-"} `);
                    }

                    if (data.StackTrace != null) {
                        console.warn(data.StackTrace);
                    }

                }
            }
        }


    }

    const values = React.useMemo(
        () => ({
            setTitle,
            pageTitle,
            signInState,
            signIn,
            baseUrl,
            signOut,
            userData,
            api,
            token,
            apiError,
            acceptedTerms,
            acceptTerms,
            setAcceptedTerms,
            muiMarkdownOverrides,
            needChangePassword,
            updatePassword,
            displayAcceptTerms,
            setDisplayAcceptTerms,
            isInternal: (userData?.userGroup ?? 4) < 4,
            networkError
        }), [pageTitle, signInState, token, acceptedTerms, needChangePassword, displayAcceptTerms, networkError]);
    return <ApplicationContext.Provider value={values}>{children}</ApplicationContext.Provider>;
}


// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useApplicationContext = (): ApplicationContextProps => {
    const context = React.useContext(ApplicationContext);
    if (context === undefined || context === null) {
        throw new Error(
            '`useApplicationContect` hook must be used within a `ApplicationContextProvider` component',
        );
    }
    return context;
};
